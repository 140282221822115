import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { LoginDto } from 'src/app/modules/shared/dto/login.dto';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { WebsocketService } from '../websocket/websocket.service';
import { E_PermissionUser } from 'src/app/modules/shared/enums/permissionUser.enum';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private urlLogin = environment.url+"auth";

  constructor(
    private apiService: ApiService,
    private router: Router,
    private webSocketService: WebsocketService
  ) { }


  login(loginDto: LoginDto): void {
    this.apiService.post(this.urlLogin, loginDto).subscribe(async(result) => {
      if(result.status === "OK") {
        localStorage.setItem("actual_client_id", result.data.user.id);
        localStorage.setItem("role_user_api", result.data.user.permission);
        localStorage.setItem("token_auth_api", result.data.token);
        this.webSocketService.connect();
        if(result.data.user.permission === E_PermissionUser.CUST) {
          localStorage.setItem("point_sale_imei", result.data.user.pointSale.imei);
          this.router.navigateByUrl(`/point-sale/${result.data.user.pointSale.imei}`)
        }
        else if(result.data.user.permission === E_PermissionUser.MAN) {
          localStorage.setItem("zone", result.data.user.zone);
          this.router.navigateByUrl('/point-sale');
        }
        else this.router.navigateByUrl('/point-sale');
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#16c71c",
          color: "#fff"
        });
      }
      else if(result.status === "ERROR") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#f72020",
          color: "#fff"
        });
        this.router.navigateByUrl('/login');
      }
    })
  }


  logout(): void {
    localStorage.clear();
    this.webSocketService.disconnect();
    this.router.navigateByUrl('/login')
  }

  isConnected(): boolean {
    if(localStorage.getItem('token_auth_api')) return true;
    else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }

  tokenExpired(): void {
    localStorage.clear();
    Swal.fire({
      title: 'Session expired',
      toast: true,
      timer: 2000,
      position: "top-right",
      showConfirmButton: false,
      background: "#f72020",
      color: "#fff"
    });
    this.router.navigateByUrl('/login');
  }

  isAdmin(): boolean {
    if(localStorage.getItem('role_user_api') === E_PermissionUser.ADM) return true;
    else {
      // const imei = localStorage.getItem("point_sale_imei");
      // this.router.navigateByUrl(`/point-sale/${imei}`);
      return false;
    }
  }

  isManager(): boolean {
    if(localStorage.getItem('role_user_api') === E_PermissionUser.MAN) return true;
    else {
      return false;
    }
  }

  cannotAccesLogin(): boolean {
    if(localStorage.getItem('token_auth_api')) {
      const imei = localStorage.getItem("point_sale_imei");
      if(imei) this.router.navigateByUrl(`/point-sale/${imei}`);
      else this.router.navigateByUrl('/point-sale');
      return false;
    }
    else return true;

  }

  isCustomer(): boolean {
    if(localStorage.getItem('role_user_api') === E_PermissionUser.CUST) return true;
    return false;
  }
}
