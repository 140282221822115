import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { LoginPageComponent } from './login-page/login-page.component';
import { AlreadyConnectGuard } from '../shared/guards/already-connected/already-connect.guard';

export const routes: Routes = [
  {
    path: '',
    component: LoginPageComponent,
    canActivate: [AlreadyConnectGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class AuthRoutingModule {}

