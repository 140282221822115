import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AlimentationCreationUpdateDto, PointSaleCreationUpdateDto } from 'src/app/modules/shared/dto/point-sale.dto';
import { E_TimeZone, E_Zone } from 'src/app/modules/shared/enums/point-sale.enum';
import { I_AlimentationForCheck, I_AlimentationPointSale } from 'src/app/modules/shared/interfaces/alimentation.interface';
import { I_PointSale, I_PointSaleCheck } from 'src/app/modules/shared/interfaces/point-sale.interface';
import { GlobalService } from 'src/app/services/global/global.service';
import { PointSaleService } from 'src/app/services/point-sale/point-sale.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-form-update-point-sale',
  templateUrl: './form-update-point-sale.component.html',
  styleUrls: ['./form-update-point-sale.component.scss']
})
export class FormUpdatePointSaleComponent implements OnInit, OnDestroy{

  formUpdatePointSale: FormGroup;
  enumZoneEntries = Object.entries(E_Zone);
  enumZoneValues = Object.values(E_Zone);
  enumTimeZoneEntries = Object.entries(E_TimeZone);
  enumTimeZoneValues = Object.values(E_TimeZone);
  alimentations: I_AlimentationPointSale[] = [];
  actualAlimentation: I_AlimentationPointSale | null = null;
  actualPointSale: I_PointSale | null = null;
  consignValue: {canal: string, value: number}[] = [];
  consignArray: number[] = [1,2,3,4];
  consignAlimentation = {canal1: 0, canal2: 0, canal3: 0, canal4: 0, canal5:0, canal6: 0, canal7:0, canal8: 0};
  private unsubscribeAlim$: Subscription = new Subscription();
  private unsubscribeAllAlim$: Subscription = new Subscription();
  private unsubscribePdv$: Subscription = new Subscription();

  constructor(
    private pointSaleService: PointSaleService,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private activeModal: NgbActiveModal
  ){
    this.formUpdatePointSale = this.formBuilder.group({
      pointSale: this.formBuilder.group({
        name: ['', Validators.required],
        zone: ['', Validators.required],
        timezone: ['', Validators.required],
        previousConsumption: ['', Validators.required],
      }),
      alimentation: this.formBuilder.group({
        name: [''],
        address: [1, Validators.required],
        defaultUp1: [80, Validators.required],
        defaultDown1: [20, Validators.required],
        defaultUp2: [80, Validators.required],
        defaultDown2: [20, Validators.required],
        defaultUp3: [80, Validators.required],
        defaultDown3: [20, Validators.required],
        consign1: this.formBuilder.group({
          canal1: [false],
          canal2: [false],
          canal3: [false],
          canal4: [false],
          canal5: [false],
          canal6: [false],
          canal7: [false],
          canal8: [false],
        }),
        consign2: this.formBuilder.group({
          canal1: [false],
          canal2: [false],
          canal3: [false],
          canal4: [false],
          canal5: [false],
          canal6: [false],
          canal7: [false],
          canal8: [false],
        }),
        consign3: this.formBuilder.group({
          canal1: [false],
          canal2: [false],
          canal3: [false],
          canal4: [false],
          canal5: [false],
          canal6: [false],
          canal7: [false],
          canal8: [false],
        }),
        consign4: this.formBuilder.group({
          canal1: [false],
          canal2: [false],
          canal3: [false],
          canal4: [false],
          canal5: [false],
          canal6: [false],
          canal7: [false],
          canal8: [false],
        })
      })
    })
  }

  ngOnInit(): void {
    this.pointSaleService.alimentation$.next(null);
    this.unsubscribePdv$ = this.pointSaleService.pointSale$.subscribe(value => {
        if(value !== null){
          this.alimentations = value.alimentations;
          this.actualPointSale = value;
          if(this.alimentations.length > 0) {
            this.pointSaleService.alimentation$.next(value.alimentations[0]);
          }
          this.pointSale?.patchValue({
            name: value.name,
            zone: value.zone,
            timezone: value.timezone,
            previousConsumption: value.previousConsumption
          });
        }
      });
      // Mise en place des données d'alimentation
      this.unsubscribeAlim$ = this.pointSaleService.alimentation$.subscribe(alimentation => {
        if(alimentation !== null) {
          this.setAlimentationData(alimentation);
        }

      });

      this.unsubscribeAllAlim$ = this.pointSaleService.alimentations$.subscribe(value => {
        if(value !== null) {
          this.alimentations = value;
          if(this.alimentations.length > 0) {
            this.pointSaleService.alimentation$.next(this.alimentations[0]);
          }
        }
      })
  }

  ngOnDestroy(): void {
      this.unsubscribeAlim$.unsubscribe();
      this.unsubscribeAllAlim$.unsubscribe();
      this.unsubscribePdv$.unsubscribe();
  }

  setAlimentationData(alimentation: I_AlimentationPointSale): void {
    this.actualAlimentation = alimentation;
    this.consignValue = [];
    for (const [key, value] of Object.entries(alimentation as I_AlimentationPointSale)) {
      if(key.startsWith('canal')) this.consignValue.push({canal: key, value: value});
    }
    this.alimentation.reset({
      name: alimentation.name,
      address: alimentation.address,
      defaultUp1: alimentation.consign1Haut,
      defaultDown1: alimentation.consign1Bas,
      defaultUp2: alimentation.consign2Haut,
      defaultDown2: alimentation.consign2Bas,
      defaultUp3: alimentation.consign3Haut,
      defaultDown3: alimentation.consign3Bas,
    });
    this.enableAllConsing();
    this.assignValueForConsign();
    this.manageDisableEnable();
  }

  get pointSale() {
    return this.formUpdatePointSale.get('pointSale') as FormGroup;
  }

  get alimentation() {
    return this.formUpdatePointSale.get('alimentation') as FormGroup;
  }

  get consign1() {
    return this.formUpdatePointSale.get('alimentation.consign1') as FormGroup;
  }

  get consign2() {
    return this.formUpdatePointSale.get('alimentation.consign2') as FormGroup;
  }

  get consign3() {
    return this.formUpdatePointSale.get('alimentation.consign3') as FormGroup;
  }

  get consign4() {
    return this.formUpdatePointSale.get('alimentation.consign4') as FormGroup;
  }

  /**
   * Fonction pour avoir la consign à partir de la valeur du canal
   * @param number La valeur du canal
   */
  getConsignForAlimentation(number: number): FormGroup|null {
    if(number === 0) return null;
    else return this.formUpdatePointSale.get(`alimentation.consign${number}`) as FormGroup
  }

  /**
   * Fonction pour assigner la consign à partir de la valeur du canal
   * @param number La valeur du canal
   */
  assignValueForConsign(): void {
    for (const consignObject of this.consignValue) {
      if(consignObject.value !== 0 ) {
        const consigne = this.getConsignForAlimentation(consignObject.value);
        const canal = consigne?.get(`${consignObject.canal}`);
        canal?.patchValue(true)
      }
    }
  }

  /**
   * Fonction pour manage les champs disabled et enabled
   * @param consign la consigne choisie
   * @param canal Le canal choisi
   * @param event L'evenement
   */
  manageDisableEnable(consign?: number, canal?: number, event?: any): void {
    let consignToDisable: number[] = [];
    if(event && canal && consign){
      const checked = event.target.checked;
      if(checked) {
        consignToDisable = this.consignArray.filter(consignEnable => consignEnable !== consign);
        for (const consign of consignToDisable) {
          const consignToDisable = this.getConsignForAlimentation(consign);
          consignToDisable?.get(`canal${canal}`)?.disable();
        }
      }
      else {
        for (const consign of this.consignArray) {
          const consignToDisable = this.getConsignForAlimentation(consign);
          consignToDisable?.get(`canal${canal}`)?.enable();
        }
      }
    }
    // Disable toutes les consignes qui n'ont pas de valeur
    else {
     for(const canal of this.consignValue) {
      if(canal.value !== 0) {
        const consignToDisable = this.consignArray.filter(consign => consign !== canal.value);
        for(const consign of consignToDisable) {
          const consignToDisable = this.getConsignForAlimentation(consign);
          consignToDisable?.get(`${canal.canal}`)?.disable();
        }
      }
     }
    }
  }

  /**
   * Fonction pour changer d'alimentation
   * @param event
   */
  changeAlimentation(event: any): void {
    const checkValue = this.checkValueAlimentationBeforeChanging();
    if(checkValue) {
      Swal.fire({
        title: 'Changes have been done',
        text: "Do you want to save before changing alimentation?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Save',
        reverseButtons: true,
        customClass: {}
      }).then((result) => {
        if(result.isConfirmed) this.updatePointSale();
      })
    }
    const alimentation: I_AlimentationPointSale | undefined = this.alimentations.find(alim => alim.id === Number(event.target.value));
    if(alimentation) {
      this.pointSaleService.alimentation$.next(alimentation);
      this.actualAlimentation = alimentation;
    }

  }

  /**
   * Fonction pour remettre toutes les config à enable
   */
  enableAllConsing(): void {
    for(let consignValue = 1; consignValue < 5; consignValue++) {
      for(let canalValue = 1; canalValue < 9; canalValue++) {
        const consign = this.getConsignForAlimentation(consignValue);
        consign?.get(`canal${canalValue}`)?.enable();
      }
    }
  }

  /**
   * Fonction pour avoir la valeur de chaque canal pour la création ou la modification d'alimentation
   */
    getConsignAlimentation(): void {
      for (const [key,value] of Object.entries(this.alimentation.value)) {
        if(key.startsWith('consign')) {
          for (const [keyConsign, valueConsign] of Object.entries(value as any)) {
            if(valueConsign === true) {
              const canalValue = Number(key.replace("consign",""));
              if(keyConsign in this.consignAlimentation) (this.consignAlimentation as any)[keyConsign] = canalValue
            }
          }
        }
      }
    }


    close(): void {
      const checkValueAlimentation = this.checkValueAlimentationBeforeChanging();
      if(checkValueAlimentation) {
        Swal.fire({
          title: 'Changes have been done',
          text: "Do you want to save before changing alimentation?",
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Cancel',
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Save',
          reverseButtons: true,
          customClass: {}
        }).then((result) => {
          if(result.isConfirmed) {
            this.updatePointSale();
            this.activeModal.close();
          }
          else if(result.isDismissed) this.activeModal.close()
        })
      }
      else this.activeModal.close();
    }

  updatePointSale(): void {
  this.consignAlimentation = {canal1: 0, canal2: 0, canal3: 0, canal4: 0, canal5:0, canal6: 0, canal7:0, canal8: 0};
    if(this.formUpdatePointSale.valid) {
      const pointSaleDto: PointSaleCreationUpdateDto = {
        name: this.pointSale.value.name,
        imei: this.pointSaleService.pointSale$.value?.imei ?? "",
        zone: this.globalService.getEnumKeyFromValue(this.enumZoneEntries,this.pointSale.value.zone),
        timezone: this.globalService.getEnumKeyFromValue(this.enumTimeZoneEntries,this.pointSale.value.timezone),
        previousConsumption: this.pointSale.value.previousConsumption,
      }
      if(this.actualPointSale !== null) {
        if(this.actualPointSale.alimentations.length === 0) this.pointSaleService.updatePointSale(this.actualPointSale.id, pointSaleDto);
        else if(this.actualPointSale.alimentations.length > 0) {
          this.getConsignAlimentation();
          const alimentationDto: AlimentationCreationUpdateDto = {
            name: this.alimentation.value.name,
            address: this.alimentation.value.address,
            canal1: this.consignAlimentation.canal1,
            canal2: this.consignAlimentation.canal2,
            canal3: this.consignAlimentation.canal3,
            canal4: this.consignAlimentation.canal4,
            canal5: this.consignAlimentation.canal5,
            canal6: this.consignAlimentation.canal6,
            canal7: this.consignAlimentation.canal7,
            canal8: this.consignAlimentation.canal8,
            consign1Haut: this.alimentation.value.defaultUp1,
            consign1Bas: this.alimentation.value.defaultDown1,
            consign2Haut: this.alimentation.value.defaultUp2,
            consign2Bas: this.alimentation.value.defaultDown2,
            consign3Haut: this.alimentation.value.defaultUp3,
            consign3Bas: this.alimentation.value.defaultDown3,
            idPointSale: this.pointSaleService.pointSale$.value?.id ?? 0
            };
          this.pointSaleService.updatePointSaleUpdateAlimentation(Number(this.pointSaleService.pointSale$.value?.id), pointSaleDto, Number(this.pointSaleService.alimentation$.value?.id), alimentationDto);
        }
      }
    }
  }

  deleteAlimentation(): void {
      Swal.fire({
        title: 'Delete alimentation',
        text: "Are you sure you want to delete the last alimentation?",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Delete',
        reverseButtons: true,
        customClass: {}
      }).then((result) => {
        if (result.isConfirmed) {
          if(this.pointSaleService.pointSale$.value !== null)  this.pointSaleService.deleteAlimentation(this.pointSaleService.pointSale$.value.imei.toString());
        }
      })
  }

  /**
   * Fonction pour checker si les valeurs de l'alimentation actuelle ont été modifiées
   * @returns true ou false (True si modifiée, False si non)
   */
  checkValueAlimentationBeforeChanging(): boolean {
    this.consignAlimentation = {canal1: 0, canal2: 0, canal3: 0, canal4: 0, canal5:0, canal6: 0, canal7:0, canal8: 0};
    if(this.actualAlimentation) {
      this.getConsignAlimentation();
      const alimentation: I_AlimentationForCheck = {
        canal1: this.consignAlimentation.canal1,
        canal2: this.consignAlimentation.canal2,
        canal3: this.consignAlimentation.canal3,
        canal4: this.consignAlimentation.canal4,
        canal5: this.consignAlimentation.canal5,
        canal6: this.consignAlimentation.canal6,
        canal7: this.consignAlimentation.canal7,
        canal8: this.consignAlimentation.canal8,
        consign1Haut: this.alimentation.value.defaultUp1,
        consign1Bas: this.alimentation.value.defaultDown1,
        consign2Haut: this.alimentation.value.defaultUp2,
        consign2Bas: this.alimentation.value.defaultDown2,
        consign3Haut: this.alimentation.value.defaultUp3,
        consign3Bas: this.alimentation.value.defaultDown3,
      }
      const keysAlimentationCheck = Object.keys(alimentation) as (keyof I_AlimentationForCheck)[];
      for(let key of keysAlimentationCheck) {
        if(this.actualAlimentation[key] !== alimentation[key]) return true;
      }
      return false;
    }
    return false;

  }

}
