import { Component, OnDestroy, OnInit } from '@angular/core';
import { PointSaleService } from 'src/app/services/point-sale/point-sale.service';
import { I_PointSale } from '../../shared/interfaces/point-sale.interface';
import { E_Zone } from '../../shared/enums/point-sale.enum';
import { GlobalService } from 'src/app/services/global/global.service';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { Router } from '@angular/router';
import { E_PermissionUser } from '../../shared/enums/permissionUser.enum';
import { Subscription, fromEvent, takeUntil } from 'rxjs';

@Component({
  selector: 'app-points-of-sale',
  templateUrl: './points-of-sale.component.html',
  styleUrls: ['./points-of-sale.component.scss']
})
export class PointsOfSaleComponent implements OnInit, OnDestroy {
  pointSales: I_PointSale[] = [];
  pointSalesFound: I_PointSale[] = [];
  enumZoneValues = Object.values(E_Zone);
  enumZoneEntries = Object.entries(E_Zone);
  pointSaleOnline: I_PointSale[] = [];
  pointSaleOffline: I_PointSale[] = [];
  totalPointSale: number = 0;
  isManager: boolean = false;
  private unsubscribePdv$: Subscription = new Subscription();
  private unsubscribePdvWithParams$: Subscription = new Subscription();


  constructor(
    private pointSaleService: PointSaleService,
    private globaleService: GlobalService,
    private router: Router

  ) {

  }

  ngOnInit(): void {
    this.pointSaleService.pointSales$.next([]);
    this.pointSaleService.pointSalesWithParams$.next([]);
    if(localStorage.getItem("role_user_api") === E_PermissionUser.MAN) {
      this.isManager = true;
      this.pointSaleService.selectSearchZone$.next(localStorage.getItem("zone"));
      this.pointSaleService.getAllPointSale();
    }
    else {
      this.pointSaleService.selectSearchZone$.next(null);
      this.pointSaleService.getAllPointSale();
    }
      this.pointSaleService.totalPointSale$.subscribe(value => {
        if(value > 0) this.totalPointSale = value;
      });

      this.unsubscribePdv$ = this.pointSaleService.pointSales$.subscribe(value => {
        if(value.length > 0) {
          this.pointSales = value;
          this.pointSalesFound = value;
          this.pointSaleOnline = this.pointSales.filter(pdv => pdv?.data && pdv?.data.Online === 1);
          this.pointSaleOffline = this.pointSales.filter(pdv => !pdv?.data  || pdv?.data.Online === 0);
        }
      });

      this.unsubscribePdvWithParams$ = this.pointSaleService.pointSalesWithParams$.subscribe(value => {
          this.pointSalesFound = value;
      });



    }

    ngOnDestroy(): void {
      this.unsubscribePdv$.unsubscribe();
      this.unsubscribePdvWithParams$.unsubscribe();
  }


  changeZone(event: any): void {
    let zone = event.target.value;
    if(zone === "null") zone = null;
    this.pointSaleService.selectSearchZone$.next(zone);
    this.pointSaleService.getAllPointSaleWithParams();
  }

  changeName(event: any): void {
    this.pointSaleService.inputSearchZone$.next(event.target.value)
  }

  changePointSale(event:any): void {
    if(event.target.value !== 'null') {
      const imei = event.target.value;
      this.router.navigateByUrl(`/point-sale/${imei}`)
    }
  }


}
