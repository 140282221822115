<app-header></app-header>
<div class="page">
    <div class="d-flex justify-content-center align-items-center mt-4 mb-4">
        <div class="sale">
            Choose a point of sale :
        </div>
        <div class="form-input mm">
            <input type="text" class="form-control" placeholder="Point of sale">
        </div>
    </div>
    <h1 class="titre2 mt-4 mb-4">
        Nantes_MCP_TR800
    </h1>
    <div class="d-flex justify-content-center">
        <table class="table t-not mt-3">
            <thead>
                <tr>
                    <th>
                        Email notification
                    </th>
                    <th>
                        SMS notification
                    </th>
                    <th>
                        Trigger
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>
                        <input class="form-check-input" type="checkbox" checked>
                        <span>
                            antoine.saraiva@chanel.com
                        </span>
                    </td>
                    <td>
                        <input class="form-check-input" type="checkbox">
                        <span>
                            33647479465
                        </span>
                    </td>
                    <td>
                        <div class="d-flex align-items-center space-between">
                            <div class="cls">
                                <div>
                                    <input class="form-check-input" type="checkbox">
                                    Power
                                </div>
                                <div>
                                    <input class="form-check-input" type="checkbox">
                                    Temperature > 30
                                </div>
                                <div>
                                    <input class="form-check-input" type="checkbox">
                                    Consumption > 11
                                </div>
                            </div>
                            <div class="action">
                                <a data-bs-toggle="modal" href="#ModifNotifications" role="button"><i class="fa-solid fa-pen"></i></a>
                                <a href="" role="button"><i class="fa-solid fa-trash"></i></a>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input class="form-check-input" type="checkbox" checked>
                        <span>
                            antoine.saraiva@chanel.com
                        </span>
                    </td>
                    <td>
                        <input class="form-check-input" type="checkbox">
                        <span>
                            33647479465
                        </span>
                    </td>
                    <td>
                        <div class="d-flex align-items-center space-between">
                            <div class="cls">
                                <div>
                                    <input class="form-check-input" type="checkbox">
                                    Power
                                </div>
                                <div>
                                    <input class="form-check-input" type="checkbox">
                                    Temperature > 30
                                </div>
                                <div>
                                    <input class="form-check-input" type="checkbox">
                                    Consumption > 11
                                </div>
                            </div>
                            <div class="action">
                                <a data-bs-toggle="modal" href="#ModifNotifications" role="button"><i class="fa-solid fa-pen"></i></a>
                                <a href="" role="button"><i class="fa-solid fa-trash"></i></a>
                            </div>
                        </div>
                    </td>
                </tr>           
            </tbody>
        </table>
    </div>
    <div class="bt mt-5">
        <button type="button" class="btn btn-fh add" data-bs-toggle="modal" href="#AddNotifications" role="button">Add</button>
    </div>

    <!-- Modals Modif -->
    <div class="modal fade" id="ModifNotifications" aria-hidden="true" aria-labelledby="AddSettingsLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="AddSettingsLabel">Modification of a notification form </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-input mb-3">
                    <label class="form-label">Email : </label>
                    <input type="mail" class="form-control" placeholder="Email">
                </div>
                <div class="form-input mb-3">
                    <label class="form-label">Telephone numbers :</label>
                    <input type="text" class="form-control" placeholder="Telephone numbers">
                </div>
                <div class="form-input mb-3">
                    <label class="form-label">Temperature threshold : </label>
                    <input type="text" class="form-control" placeholder="Temperature threshold">  
                </div>
                <div class="form-input mb-3">
                    <label class="form-label">Consumption threshold : </label>
                    <input type="text" class="form-control" placeholder="Consumption threshold">
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-fh">Confirm</button>
            </div>
            </div>
        </div>
    </div>

    <!-- Modals Creation -->
    <div class="modal fade" id="AddNotifications" aria-hidden="true" aria-labelledby="ModifSettingsLabel" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="AddSettingsLabel">Notification creation form                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="form-input mb-3">
                    <label class="form-label">Email : </label>
                    <input type="mail" class="form-control" placeholder="Email">
                </div>
                <div class="form-input mb-3">
                    <label class="form-label">Telephone numbers :</label>
                    <input type="text" class="form-control" placeholder="Telephone numbers">
                </div>
                <div class="form-input mb-3">
                    <label class="form-label">Temperature threshold : </label>
                    <input type="text" class="form-control" placeholder="Temperature threshold">  
                </div>
                <div class="form-input mb-3">
                    <label class="form-label">Consumption threshold : </label>
                    <input type="text" class="form-control" placeholder="Consumption threshold">
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-fh">Create</button>
            </div>
            </div>
        </div>
    </div>
</div>
