
  <div class="modal-header">
      <h5 class="modal-title" id="ModifSettingsLabel">Point of sale modification form</h5>
      <button type="button" class="btn-close"  aria-label="Close" (click)="close()"></button>
  </div>
  <form [formGroup]="formUpdatePointSale" (submit)="updatePointSale()">
  <div class="modal-body">
    <div [formGroup]="pointSale">
      <div class="form-input mb-3">
          <label class="form-label">Name of point of sale :</label>
          <input type="text" class="form-control" placeholder="Name" formControlName="name">
      </div>
      <div class="form-input mb-3">
          <label class="form-label">Zone :</label>
          <select class="form-select" aria-label="Default select example" formControlName="zone">
            <option *ngFor="let zone of enumZoneValues" [value]="zone">{{zone}}</option>
          </select>
      </div>

      <div class="form-input mb-3">
          <label class="form-label">TimeZone :</label>
          <select class="form-select" aria-label="Default select example" formControlName="timezone">
            <option *ngFor="let timezone of enumTimeZoneValues" [value]="timezone">GMT {{timezone}}</option>
          </select>
      </div>
      <div class="form-input mb-3">
          <label class="form-label">Previous consumption :</label>
          <input type="text" class="form-control" placeholder="Previous consumption" formControlName="previousConsumption">
      </div>
    </div>

    <div [formGroup]="alimentation" *ngIf="alimentations.length > 0">
      <div class="form-input mb-3">
        <label class="form-label">Alimentations :</label>
        <select class="form-select" aria-label="Default select example" (change)="changeAlimentation($event)">
          <option *ngIf="alimentations.length === 0">Aucune alimentation</option>
          <option *ngFor="let alimentation of alimentations" [value]="alimentation.id" >{{alimentation.name}}</option>
        </select>
    </div>



    <!-- Données de base de l'alimentation -->

    <div class="row" *ngIf="alimentations.length > 0">
        <div class="col-6 mb-3">
            <label class="form-label">Power supply name :</label>
            <input type="text" class="form-control" placeholder="Name" formControlName="name">
        </div>
        <div class="col-6 mb-3">
            <label class="form-label">Power supply address :</label>
            <input type="text" class="form-control" placeholder="Address" formControlName="address" readonly>
        </div>
    </div>

      <!-- Consigne 1 -->
      <div class="d-flex form-input cin" *ngIf="alimentations.length > 0" [formGroup]="consign1">
          <label class="form-label">Consigne 1 :</label>
          <div class="d-flex">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal1" (change)="manageDisableEnable(1,1, $event)">
                <label class="form-check-label" for="flexCheckDefault">
                    1
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal2" (change)="manageDisableEnable(1,2, $event)">
                <label class="form-check-label" for="flexCheckChecked">
                    2
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal3" (change)="manageDisableEnable(1,3, $event)">
                <label class="form-check-label" for="flexCheckChecked">
                    3
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal4" (change)="manageDisableEnable(1,4, $event)">
                <label class="form-check-label" for="flexCheckChecked">
                    4
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal5" (change)="manageDisableEnable(1,5, $event)">
                <label class="form-check-label" for="flexCheckChecked">
                    5
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal6" (change)="manageDisableEnable(1,6, $event)">
                <label class="form-check-label" for="flexCheckChecked">
                    6
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal7" (change)="manageDisableEnable(1,7, $event)">
                <label class="form-check-label" for="flexCheckChecked">
                    7
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal8" (change)="manageDisableEnable(1,8, $event)">
                <label class="form-check-label" for="flexCheckChecked">
                    8
                </label>
            </div>
          </div>
      </div>

      <!-- Consigne 2 -->
        <div class="d-flex form-input cin" *ngIf="alimentations.length > 0" [formGroup]="consign2">
            <label class="form-label">Consigne 2 :</label>
            <div class="d-flex">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal1" (change)="manageDisableEnable(2,1, $event)">
                    <label class="form-check-label" for="flexCheckDefault">
                        1
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal2" (change)="manageDisableEnable(2,2, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        2
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal3" (change)="manageDisableEnable(2,3, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        3
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal4" (change)="manageDisableEnable(2,4, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        4
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal5" (change)="manageDisableEnable(2,5, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        5
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal6" (change)="manageDisableEnable(2,6, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        6
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal7" (change)="manageDisableEnable(2,7, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        7
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal8" (change)="manageDisableEnable(2,8, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        8
                    </label>
                </div>
            </div>
        </div>
        <!-- Consigne 3 -->
        <div class="d-flex form-input cin" *ngIf="alimentations.length > 0" [formGroup]="consign3">
            <label class="form-label">Consigne 3 :</label>
            <div class="d-flex">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal1" (change)="manageDisableEnable(3,1, $event)">
                    <label class="form-check-label" for="flexCheckDefault">
                        1
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal2" (change)="manageDisableEnable(3,2, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        2
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal3" (change)="manageDisableEnable(3,3, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        3
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal4" (change)="manageDisableEnable(3,4, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        4
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal5" (change)="manageDisableEnable(3,5, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        5
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal6" (change)="manageDisableEnable(3,6, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        6
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal7" (change)="manageDisableEnable(3,7, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        7
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal8" (change)="manageDisableEnable(3,8, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        8
                    </label>
                </div>
            </div>
        </div>

        <!-- ON/OFF -->
        <div class="d-flex form-input cin" *ngIf="alimentations.length > 0" [formGroup]="consign4">
            <label class="form-label">ON/OFF :</label>
            <div class="d-flex">
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal1" (change)="manageDisableEnable(4,1, $event)">
                    <label class="form-check-label" for="flexCheckDefault">
                        1
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal2" (change)="manageDisableEnable(4,2, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        2
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal3" (change)="manageDisableEnable(4,3, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        3
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal4" (change)="manageDisableEnable(4,4, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        4
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal5" (change)="manageDisableEnable(4,5, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        5
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal6" (change)="manageDisableEnable(4,6, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        6
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal7" (change)="manageDisableEnable(4,7, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        7
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="canal8" (change)="manageDisableEnable(4,8, $event)">
                    <label class="form-check-label" for="flexCheckChecked">
                        8
                    </label>
                </div>
            </div>
        </div>

              <!-- Default 1 -->
      <div class="row mt-3" *ngIf="alimentations.length > 0">
        <div class="col-6 mb-3">
            <label class="form-label">Default up 1 :</label>
            <input type="number" class="form-control" placeholder="Default up 1" formControlName="defaultUp1">
        </div>
        <div class="col-6 mb-3">
            <label class="form-label">Default down 1 :</label>
            <input type="number" class="form-control" placeholder="Default down 1" formControlName="defaultDown1">
        </div>
      </div>

      <!-- Default 2 -->
      <div class="row" *ngIf="alimentations.length > 0">
          <div class="col-6 mb-3">
              <label class="form-label">Default up 2 :</label>
              <input type="number" class="form-control" placeholder="Default up 2" formControlName="defaultUp2">
          </div>
          <div class="col-6 mb-3">
              <label class="form-label">Default down 2 :</label>
              <input type="number" class="form-control" placeholder="Default down 2" formControlName="defaultDown2">
          </div>
      </div>

      <!-- Default 3 -->
      <div class="row" *ngIf="alimentations.length > 0">
              <div class="col-6 mb-3">
                  <label class="form-label">Default up 3 :</label>
                  <input type="number" class="form-control" placeholder="Default up 3" formControlName="defaultUp3">
              </div>
              <div class="col-6 mb-3">
                  <label class="form-label">Default down 3 :</label>
                  <input type="number" class="form-control" placeholder="Default down 3" formControlName="defaultDown3">
              </div>
      </div>
      </div>


      </div>

      <div class="d-flex space-between mt-3" *ngIf="alimentations.length > 0">
          <button class="btn btn-danger" type="button" (click)="deleteAlimentation()">Delete</button>
      </div>
  <div class="modal-footer">
      <button class="btn btn-fh" type="submit" [disabled]="!formUpdatePointSale.valid" [ngClass]="{'button-disabled': !formUpdatePointSale.valid}">Validate</button>
  </div>
</form>

