import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription, fromEvent, takeUntil } from 'rxjs';
import { UserDto } from 'src/app/modules/shared/dto/user.dto';
import { E_PermissionUser } from 'src/app/modules/shared/enums/permissionUser.enum';
import { E_Zone } from 'src/app/modules/shared/enums/point-sale.enum';
import { I_PointSale } from 'src/app/modules/shared/interfaces/point-sale.interface';
import { I_User } from 'src/app/modules/shared/interfaces/user.interface';
import { GlobalService } from 'src/app/services/global/global.service';
import { PointSaleService } from 'src/app/services/point-sale/point-sale.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-form-user-permission',
  templateUrl: './form-user-permission.component.html',
  styleUrls: ['./form-user-permission.component.scss']
})
export class FormUserPermissionComponent implements OnInit, OnDestroy {
  formUserPermission: FormGroup;
  enumUserEntries = Object.entries(E_PermissionUser)
  enumUserValues = Object.values(E_PermissionUser);
  enumZoneEntries = Object.entries(E_Zone);
  enumZoneValues = Object.values(E_Zone);
  pointSales: I_PointSale[] = [];
  isCustomer: boolean = false;
  private unsubscribePdv$: Subscription = new Subscription();



  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private globalService: GlobalService,
    private pointSaleService: PointSaleService
  ) {
    this.formUserPermission = this.formBuilder.group({
      name: ["", Validators.required],
      surname: ["", Validators.required],
      phone: ["", Validators.required],
      email: ["", Validators.required],
      permission: [E_PermissionUser.ADM, Validators.required],
      zone: [E_Zone.EUPARIS, Validators.required],
      password: ["", Validators.required],
      idPointSale: [""]
    })
  }

  ngOnInit(): void {
    this.pointSaleService.pointSales$.next([]);
    this.unsubscribePdv$ = this.pointSaleService.pointSales$.subscribe(value => {
      if(value.length> 0) {
        this.pointSales = value
        this.formUserPermission.patchValue({idPointSale: value[0].id})
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribePdv$.unsubscribe();
}



  saveUser() : void {
    if(this.formUserPermission.valid) {
      let userDto: UserDto = {
        name: this.formUserPermission.value.name,
        surname: this.formUserPermission.value.surname,
        phone: this.formUserPermission.value.phone,
        email: this.formUserPermission.value.email,
        permission: this.globalService.getEnumKeyFromValue(this.enumUserEntries,this.formUserPermission.value.permission),
        zone: this.globalService.getEnumKeyFromValue(this.enumZoneEntries,this.formUserPermission.value.zone),
        password: this.formUserPermission.value.password
      };
      if(this.formUserPermission.value.permission === E_PermissionUser.CUST) userDto = {...userDto, idPointSale: Number(this.formUserPermission.value.idPointSale)}
      this.userService.createUser(userDto);
      this.formUserPermission.reset({
        permission: E_PermissionUser.ADM,
        zone: E_Zone.EUPARIS
      });
      this.isCustomer = false;
    }
  }

  changePermission(event: any): void {
    if(event.target.value === E_PermissionUser.CUST) this.isCustomer = true;
    else this.isCustomer = false;
  }

}
