import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../../environments/environment'
import { I_APIResponse } from 'src/app/modules/shared/interfaces/apiResponse.interface';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  baseUrl: string = environment.url;
  constructor(
    private http: HttpClient
  ) { }

  get(url: string, options?: object): Observable<I_APIResponse> {
    return this.http.get<I_APIResponse>(url, {...options});
  }

  post(url: string, body: object, options?: object): Observable<I_APIResponse> {
    return this.http.post<I_APIResponse>(url, {...body}, {...options});
  }

  put(url: string, body: object, options?:object): Observable<I_APIResponse> {
    return this.http.put<I_APIResponse>(url, {...body},{...options});
  }

  delete(url: string, options?: object): Observable<I_APIResponse> {
    return this.http.delete<I_APIResponse>(url, {...options});
  }

}
