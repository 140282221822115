import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { PointSaleHoraireDto } from 'src/app/modules/shared/dto/point-sale.dto';
import { I_PointSale } from 'src/app/modules/shared/interfaces/point-sale.interface';
import { PointSaleService } from 'src/app/services/point-sale/point-sale.service';

@Component({
  selector: 'app-form-hour-point-sale',
  templateUrl: './form-hour-point-sale.component.html',
  styleUrls: ['./form-hour-point-sale.component.scss']
})
export class FormHourPointSaleComponent implements OnInit, OnDestroy{

  formHourPointSale: FormGroup;
  private unsubscribePdv$: Subscription = new Subscription();

  constructor(
    private pointSaleService: PointSaleService,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal
  ) {
    this.formHourPointSale = this.formBuilder.group({
      mondayMatin: ["", Validators.required],
      mondaySoir: ["", Validators.required],
      tuesdayMatin: ["", Validators.required],
      tuesdaySoir: ["", Validators.required],
      wednesdayMatin: ["", Validators.required],
      wednesdaySoir: ["", Validators.required],
      thursdayMatin: ["", Validators.required],
      thursdaySoir: ["", Validators.required],
      fridayMatin: ["", Validators.required],
      fridaySoir: ["", Validators.required],
      saturdayMatin: ["", Validators.required],
      saturdaySoir: ["", Validators.required],
      sundayMatin: ["", Validators.required],
      sundaySoir: ["", Validators.required],
    })
  }

  ngOnInit(): void {
      this.unsubscribePdv$ = this.pointSaleService.pointSale$.subscribe(value => {
        if(value !== null) {
          this.formHourPointSale.patchValue({
            mondayMatin: value.horaires.Monday.matin,
            mondaySoir: value.horaires.Monday.soir,
            tuesdayMatin: value.horaires.Tuesday.matin,
            tuesdaySoir: value.horaires.Tuesday.soir,
            wednesdayMatin: value.horaires.Wednesday.matin,
            wednesdaySoir: value.horaires.Wednesday.soir,
            thursdayMatin: value.horaires.Thursday.matin,
            thursdaySoir: value.horaires.Thursday.soir,
            fridayMatin: value.horaires.Friday.matin,
            fridaySoir: value.horaires.Friday.soir,
            saturdayMatin: value.horaires.Saturday.matin,
            saturdaySoir: value.horaires.Saturday.soir,
            sundayMatin: value.horaires.Sunday.matin,
            sundaySoir: value.horaires.Sunday.soir,
          })
        }
      })
  }  ngOnDestroy(): void {
      this.unsubscribePdv$.unsubscribe();
  }

  close(): void {
    this.activeModal.close();
  }

  updateHourPointSale(): void {
    if(this.formHourPointSale.valid) {
      const hourData: PointSaleHoraireDto = {
        horaires: {
          Monday: {matin: this.formHourPointSale.value.mondayMatin, soir: this.formHourPointSale.value.mondaySoir},
          Tuesday: {matin: this.formHourPointSale.value.tuesdayMatin, soir: this.formHourPointSale.value.tuesdaySoir},
          Wednesday: {matin: this.formHourPointSale.value.wednesdayMatin, soir: this.formHourPointSale.value.wednesdaySoir},
          Thursday: {matin: this.formHourPointSale.value.thursdayMatin, soir: this.formHourPointSale.value.thursdaySoir},
          Friday: {matin: this.formHourPointSale.value.fridayMatin, soir: this.formHourPointSale.value.fridaySoir},
          Saturday: {matin: this.formHourPointSale.value.saturdayMatin, soir: this.formHourPointSale.value.saturdaySoir},
          Sunday: {matin: this.formHourPointSale.value.sundayMatin, soir: this.formHourPointSale.value.sundaySoir},
        }
      }
      this.pointSaleService.updateHourPointSale(Number(this.pointSaleService.pointSale$.value?.id),hourData);
      this.close();
    }
  }


}
