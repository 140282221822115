<div class="content-login">

    <div class="left-login">
      <form [formGroup]="formLogin" (submit)="login()">
        <h1 class="signtitle">Sign in</h1>
        <div class="form-input mb-3 mt-5">
          <label class="form-label">Email</label>
          <input type="email" class="form-control" placeholder="name@example.com" formControlName="email">
        </div>
        <div class="form-input mb-3">
          <label class="form-label">Password</label>
          <input type="password" class="form-control" placeholder="********" formControlName="password">
        </div>
        <div class="bouton">
          <button type="button" class="btn btn-fh" (click)="login()">Connexion</button>
        </div>
      </form>
    </div>
    <div class="right-login"></div>
  </div>
