
<div class="modal-dialog modal-dialog-centered">
  <div class="modal-content">
    <div class="modal-header">
      <h5 class="modal-title">User modification form</h5>
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    </div>
    <form [formGroup]="formUserPermission" (submit)="saveUser()">
    <div class="modal-body">
      <div class="form-input mb-3">
          <label class="form-label">Name :</label>
          <input type="text" class="form-control" placeholder="Name" formControlName="name">
      </div>
      <div class="form-input mb-3">
          <label class="form-label">Surname :</label>
          <input type="text" class="form-control" placeholder="Surname" formControlName="surname">
      </div>
      <div class="form-input mb-3">
        <label class="form-label">Email:</label>
        <input type="tel" class="form-control" placeholder="Email" formControlName="email">
    </div>
      <div class="form-input mb-3">
          <label class="form-label">Telephone numbers:</label>
          <input type="tel" class="form-control" placeholder="Telephone numbers" formControlName="phone">
      </div>
      <div class="form-input mb-3">
        <label class="form-label">Password:</label>
        <input type="text" class="form-control" placeholder="" formControlName="password">
    </div>
      <div class="form-input mb-3">
          <label class="form-label">Permission :</label>
          <select class="form-select" aria-label="Default select example" formControlName="permission" (change)="changePermission($event)">
              <option *ngFor="let permission of enumUserValues">{{permission}}</option>
          </select>
      </div>
      <div class="form-input mb-3">
          <label class="form-label">Zone :</label>
          <select class="form-select" aria-label="Default select example" formControlName="zone">
              <option *ngFor="let zone of enumZoneValues">{{zone}}</option>
          </select>
      </div>
      <div class="form-input mb-3" *ngIf="isCustomer">
          <label class="form-label">Point of sale :</label>
          <select class="form-select" aria-label="Default select example" formControlName="idPointSale">
              <option *ngFor="let pointSale of pointSales" [value]="pointSale.id">{{pointSale.name}}</option>

          </select>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-fh" data-bs-dismiss="modal" type="submit" [disabled]="!formUserPermission.valid" [ngClass]="{'button-disabled': !formUserPermission.valid}">Confirm</button>
    </div>
    </form>
  </div>
</div>
