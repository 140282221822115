import { Injectable } from '@angular/core';
import { Socket, io } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { I_AlimentationPointSale } from 'src/app/modules/shared/interfaces/alimentation.interface';
import { I_WSType0, I_WSType1 } from 'src/app/modules/shared/interfaces/websocket.interface';

@Injectable({
  providedIn: 'root',
})
export class WebsocketService {
  private urlWss: string = environment.urlWSS;

  private socket!: Socket;
  alimentationSocket$ = new BehaviorSubject<I_WSType0|null>(null);
  pointSaleSocket$ = new BehaviorSubject<I_WSType1|null>(null);

  constructor() {
    if(localStorage.getItem("token_auth_api")) this.connect();
    else this.disconnect();
  }

  public connect(): void {
    this.socket = io(this.urlWss, { path: '/wss', transports: ['polling'] });
    this.socket.on('connect', () => {
      console.log('Connecté au websocket du serveur ID : ' + this.socket.id);
      this.socket.send({clientId: Number(localStorage.getItem('actual_client_id'))});
    });

    this.socket.on('connect_error', (error: any) => {
      console.log('La connexion au serveur a échouée');
      this.connect();
    })

    this.socket.on('alimentation', async(data: I_WSType0) => {
      this.alimentationSocket$.next(data);
    })

    this.socket.on('pointSale', async(data) => {
      this.pointSaleSocket$.next(data);
    })
  }

  public disconnect(): void {
    if (this.socket) this.socket.disconnect();
  }

  public sendMessasge(type: string, data: any): void {
    this.socket.emit(type, data);
  }
}
