<app-header></app-header>
<div class="container row content-sale mt-7">
    <div class="col-lg-6 col-md-6 col-sm-12">
        <div class="d-flex  sl-point">
            <div class="sale">
                Points of sale registered : {{totalPointSale}}
            </div>
            <div class="cnx"></div>
        </div>
        <div class="d-flex  sl-point">
            <div class="sale">
                Points of sale online : {{pointSaleOnline.length}}
            </div>
            <div class="cnx"></div>
        </div>
        <div class="d-flex sl-point">
            <div class="sale">
                Points of sale offline : {{pointSaleOffline.length}}
            </div>
            <div class="cnxOff"></div>
        </div>
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 sl-point">
        <div class="d-flex sll">
            <div class="d-flex  slpt">
                <div class="sale salex">
                    Seek a point of sale  :
                </div>
                <div class="form-input w-40" *ngIf="!isManager">
                    <select class="form-select" aria-label="Default select example" (change)="changeZone($event)">
                        <option [value]="null">All</option>
                        <option *ngFor="let zone of enumZoneValues" [value]="zone">{{zone}}</option>
                    </select>
                </div>
            </div>

            <!-- <div class="form-input mm">
              <input type="text" class="form-control" placeholder="Location of point of sale" (input)="changeName($event)">
            </div> -->
            <div class="form-input w-40x mm">
                <!-- <input type="text" class="form-control mb-1" placeholder="Location of point of sale" (input)="changeName($event)"> -->
                <select class="form-select" aria-label="Location of point of sale" (change)="changePointSale($event)" *ngIf="pointSalesFound.length > 0">
                      <option [value]="null">Choose point of sale</option>
                        <option *ngFor="let pointSale of pointSalesFound" [value]="pointSale.imei">{{pointSale.name}}</option>
                </select>
            </div>
            <!-- <div class="form-input mm">
                <select class="form-select" aria-label="Default select example">
                  <option *ngIf="pointSales.length === 0">Aucun point de vente trouvé</option>
                  <option *ngFor="let pointSale of pointSales" [value]="pointSale">{{pointSale.name}}</option>
              </select>
            </div> -->
        </div>
    </div>
</div>
