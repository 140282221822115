import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { LoginService } from 'src/app/services/login/login.service';
import { E_PermissionUser } from '../../enums/permissionUser.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.scss']
})
export class HeaderLayoutComponent {
  isAdmin: boolean = false;
  isManager: boolean = false;
  isCustomer: boolean = false;
  isMenuActive: boolean = false; // variable - activer burger

  constructor(
    private router: Router,
    private loginService: LoginService
  ){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          const currentRoute = event.url;
          this.updateActiveLinks(currentRoute);
      }
    });
    if(localStorage.getItem('role_user_api') === E_PermissionUser.CUST) this.isCustomer = true;

    this.isAdmin = this.loginService.isAdmin();
    this.isManager = this.loginService.isManager();
  }

  updateActiveLinks(currentRoute: string): void {
    const links = document.querySelectorAll('.nav-link');
    links.forEach(link => {
        if (link.getAttribute('routerLink') === currentRoute) {
            link.classList.add('active');
        } else {
            link.classList.remove('active');
        }
    });
  }

  logout(): void {
    this.loginService.logout();
  }

  toggleMenu(): void {
    this.isMenuActive = !this.isMenuActive;
    const nav = document.querySelector('.site-navigation');
    if (nav) {
      nav.classList.toggle('active', this.isMenuActive);
    }
  }
}
