import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor() { }

  getEnumKeyFromValue(entries: any, value: string): string {
    const enumFound = entries.find(([keyEnum, valueEnum]: any) => valueEnum === value);
    if(enumFound) return enumFound[0];
    return "";
  }
}
