import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserDto } from 'src/app/modules/shared/dto/user.dto';
import { E_PermissionUser } from 'src/app/modules/shared/enums/permissionUser.enum';
import { E_Zone } from 'src/app/modules/shared/enums/point-sale.enum';
import { I_PointSale } from 'src/app/modules/shared/interfaces/point-sale.interface';
import { I_User } from 'src/app/modules/shared/interfaces/user.interface';
import { GlobalService } from 'src/app/services/global/global.service';
import { PointSaleService } from 'src/app/services/point-sale/point-sale.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-form-update-user-permission',
  templateUrl: './form-update-user-permission.component.html',
  styleUrls: ['./form-update-user-permission.component.scss']
})
export class FormUpdateUserPermissionComponent implements OnInit, OnDestroy {
  formUserPermission: FormGroup;
  user: I_User | null = null;
  enumUserEntries = Object.entries(E_PermissionUser)
  enumUserValues = Object.values(E_PermissionUser);
  enumZoneEntries = Object.entries(E_Zone);
  enumZoneValues = Object.values(E_Zone);
  isCustomer: boolean = false;
  pointSales: I_PointSale[] = [];
  private unsubscribePdv$: Subscription = new Subscription();


  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private globalService: GlobalService,
    private pointSaleService: PointSaleService
  ) {
    this.formUserPermission = this.formBuilder.group({
      name: ["", Validators.required],
      surname: ["", Validators.required],
      phone: ["", Validators.required],
      email: ["", Validators.required],
      permission: ["", Validators.required],
      zone: ["", Validators.required],
      password: [''],
      idPointSale: [""]
    })
  }

  ngOnInit(): void {
    this.unsubscribePdv$ = this.pointSaleService.pointSales$.subscribe(value => this.pointSales = value)
    this.userService.user$.subscribe(value => {
     if(value !== null) {
      this.user = value as I_User;
      this.formUserPermission.patchValue({
        name: this.user?.name,
        surname: this.user?.surname,
        phone: this.user?.phone,
        email: this.user?.email,
        permission: this.user?.permission,
        zone: this.user?.zone,
        idPointSale: this.user.pointSale ? this.user.pointSale.id : this.pointSales[0].id,
      });
      if(this.user.permission === E_PermissionUser.CUST) this.isCustomer = true;
      else this.isCustomer = false;
     }
    })
  }

  ngOnDestroy(): void {
    this.unsubscribePdv$.unsubscribe();
}

  changePermission(event: any): void {
    if(event.target.value === E_PermissionUser.CUST) this.isCustomer = true;
    else this.isCustomer = false;
  }


  saveUser() : void {
    if(this.formUserPermission.valid) {
      let userDto: UserDto = {
        name: this.formUserPermission.value.name,
        surname: this.formUserPermission.value.surname,
        phone: this.formUserPermission.value.phone,
        email: this.formUserPermission.value.email,
        permission: this.globalService.getEnumKeyFromValue(this.enumUserEntries,this.formUserPermission.value.permission),
        zone: this.globalService.getEnumKeyFromValue(this.enumZoneEntries,this.formUserPermission.value.zone),
        password: this.formUserPermission.value.password
      };
      if(this.formUserPermission.value.permission === E_PermissionUser.CUST) userDto = {...userDto, idPointSale: Number(this.formUserPermission.value.idPointSale)}
      this.userService.updateUser(this.user?.id, userDto, this.formUserPermission.value.zone, this.formUserPermission.value.permission);
    }
  }
}
