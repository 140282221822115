import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { SettingsComponent } from './settings/settings.component';
import { AdminGuard } from '../shared/guards/admin-guard/admin-guard.guard';
import { ConnectedGuard } from '../shared/guards/connected-guard/connected.guard';

export const routes: Routes = [
  {
    path: '',
    component: SettingsComponent,
    canActivate: [ConnectedGuard,AdminGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class SettingsRoutingModule {}

