


 <div class="modal-header">
  <h5 class="modal-title" id="createAlimentation">Alimentation creation form</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<form [formGroup]="formAlimentation" (submit)="createAlimentation()">
<div class="modal-body">


<!-- Données de base de l'alimentation -->
  <div formArrayName="alimentations">
    <div *ngFor="let alimentation of alimentations.controls; let i = index" [formGroupName]="i">
      <div class="row">
        <div class="col-6 mb-3">
          <label class="form-label">Power supply name :</label>
          <input type="text" class="form-control" placeholder="Name" formControlName="name">
        </div>
        <div class="col-6 mb-3">
          <label class="form-label">Power supply address :</label>
          <input type="text" class="form-control" placeholder="Address" formControlName="address" readonly>
        </div>
      </div>
    </div>

  </div>
<div class="d-flex justify-content-center">
  <a role="button" (click)="addAlimentation()" *ngIf="lastAddress <= 6"><i class="fa-solid fa-circle-plus"></i></a>
</div>

    <div class="modal-footer">
      <button class="btn btn-fh" type="submit" [disabled]="!formAlimentation.valid" [ngClass]="{'button-disabled': !formAlimentation.valid}">Validate</button>
  </div>

</div>
</form>


