import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { I_User } from 'src/app/modules/shared/interfaces/user.interface';
import { UserDto } from 'src/app/modules/shared/dto/user.dto';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  private urlUser = environment.url + 'user';
  users$ = new BehaviorSubject<I_User[]>([]);
  user$ = new BehaviorSubject<I_User | null>(null);
  constructor(private apiService: ApiService) { }

  getAllUser(): void {
    this.apiService.get(this.urlUser).subscribe(result => {
      if(result.status === "OK") {
        this.users$.next(result.data);
      }
    })
  }

  createUser(userDto: UserDto): void {
    this.apiService.post(this.urlUser, userDto).subscribe(result => {
      if(result.status === "OK") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#16c71c",
          color: "#fff"
        });
        this.getAllUser();
      }
      else if(result.status === "ERROR") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#f72020",
          color: "#fff"
        })
      }
    })
  }

  updateUser(id: number | undefined, userDto: UserDto, zone: string, permission: string): void {
    this.user$.next(null)
    this.apiService.put(this.urlUser+`/${id}`, userDto).subscribe(result => {
      if(result.status === "OK") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#16c71c",
          color: "#fff"
        });
        this.getAllUser();

      }
      else if(result.status === "ERROR") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#f72020",
          color: "#fff"
        })
      }
    })
  }

  deleteUser(id: number): void {
    this.apiService.delete(this.urlUser+`/${id}`).subscribe(result => {
      if(result.status === "OK") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#16c71c",
          color: "#fff"
        });
        this.getAllUser();
      }
      else if(result.status === "ERROR") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#f72020",
          color: "#fff"
        })
      }
    })
  }
}
