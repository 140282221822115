<div class="wifi-signal">
  <div [class]="className" style="height:100%; margin-left: -5px; display: flex; justify-content: center;">
      <div class="wv4 wave" style="">
          <div class="wv3 wave" style="">
              <div class="wv2 wave" style="">
                  <div class="wv1 wave" style="">
                  </div>
              </div>
          </div>
      </div>
      <div><span>{{operateur}}</span></div>
  </div>
</div>
