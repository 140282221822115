import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-wifi-signal',
  templateUrl: './wifi-signal.component.html',
  styleUrls: ['./wifi-signal.component.scss']
})
export class WifiSignalComponent {
  value: number = 0;
  operateur: string = "";
  className: string = "waveStrength-1";

  @Input('operateur') set setOperateur(operateur: string ) {
    this.operateur = operateur;
  }

  @Input('value') set setValue(p: any) {
    if (p === "" || p === undefined) {
      p = 0;
    }
    this.value = Number(p);
    if (this.value >= 0 && this.value < 25) {
      this.className = "waveStrength-1";
    }
    if (this.value >= 25 && this.value < 50) {
      this.className = "waveStrength-2";
    }
    if (this.value >= 50 && this.value < 75) {
      this.className = "waveStrength-3";
    }
    if (this.value >= 75) {
      this.className = "waveStrength-4";
    }
  }
}
