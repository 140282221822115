
      <div class="modal-header">
          <h5 class="modal-title" id="CalendarPermission">Modification of opening/closing date </h5>
          <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
      </div>
      <form [formGroup]="formHourPointSale" (submit)="updateHourPointSale()">
        <div class="modal-body modalN">
            <div class="form-input mb-3 tcenter">
                <label class="form-label labelN">Monday : </label>
                <div  class="d-flex align-items-center">
                    <div class="m">
                        <input type="time" class="form-control" id="i" name="i" formControlName="mondayMatin">
                    </div>
                    <div class="m">-</div>
                    <div>
                        <input type="time" class="form-control" id="f" name="f" formControlName="mondaySoir">
                    </div>
                </div>
            </div>
            <div class="form-input mb-3 tcenter">
                <label class="form-label labelN">Tuesday : </label>
                <div  class="d-flex align-items-center">
                    <div class="m">
                        <input type="time" class="form-control" id="i" name="i" formControlName="tuesdayMatin">
                    </div>
                    <div class="m">-</div>
                    <div>
                        <input type="time" class="form-control" id="f" name="f" formControlName="tuesdaySoir">
                    </div>
                </div>
            </div>
            <div class="form-input mb-3 tcenter">
                <label class="form-label labelN">Wednesday : </label>
                <div  class="d-flex align-items-center">
                    <div class="m">
                        <input type="time" class="form-control" id="i" name="i" formControlName="wednesdayMatin">
                    </div>
                    <div class="m">-</div>
                    <div>
                        <input type="time" class="form-control" id="f" name="f" formControlName="wednesdaySoir">
                    </div>
                </div>
            </div>
            <div class="form-input mb-3 tcenter">
                <label class="form-label labelN">Thirsday : </label>
                <div  class="d-flex align-items-center">
                    <div class="m">
                        <input type="time" class="form-control" id="i" name="i" formControlName="thursdayMatin">
                    </div>
                    <div class="m">-</div>
                    <div>
                        <input type="time" class="form-control" id="f" name="f" formControlName="thursdaySoir">
                    </div>
                </div>
            </div>
            <div class="form-input mb-3 tcenter">
                <label class="form-label labelN">Friday : </label>
                <div  class="d-flex align-items-center">
                    <div class="m">
                        <input type="time" class="form-control" id="i" name="i" formControlName="fridayMatin">
                    </div>
                    <div class="m">-</div>
                    <div>
                        <input type="time" class="form-control" id="f" name="f" formControlName="fridaySoir">
                    </div>
                </div>
            </div>
            <div class="form-input mb-3 tcenter">
                <label class="form-label labelN">Saturday : </label>
                <div  class="d-flex align-items-center">
                    <div class="m">
                        <input type="time" class="form-control" id="i" name="i" formControlName="saturdayMatin">
                    </div>
                    <div class="m">-</div>
                    <div>
                        <input type="time" class="form-control" id="f" name="f" formControlName="saturdaySoir">
                    </div>
                </div>
            </div>
            <div class="form-input mb-3 tcenter">
                <label class="form-label labelN">Sunday : </label>
                <div  class="d-flex align-items-center">
                    <div class="m">
                        <input type="time" class="form-control" id="i" name="i" formControlName="sundayMatin">
                    </div>
                    <div class="m">-</div>
                    <div>
                        <input type="time" class="form-control" id="f" name="f" formControlName="sundaySoir">
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-fh" type="submit" [disabled]="!formHourPointSale.valid" [ngClass]="{'button-disabled': !formHourPointSale.valid}">Confirm</button>
        </div>
    </form>

