<header class="site-header">
  <button *ngIf="!isCustomer" class="menu-toggle" (click)="toggleMenu()">&#9776;</button>
  <div class="site-logo">
    <img src="../../assets/images/logo.jpg" alt="Logo">
  </div>
  <div class="site-navigation" *ngIf="isAdmin">
    <nav>
      <ul class="nav">
        <li><a routerLink="/point-sale" class="nav-link">Point of sale</a></li>
        <li><a routerLink="/users-permission" class="nav-link">Users-Permissions</a></li>
        <li><a routerLink="/settings" class="nav-link">Settings</a></li>
        <!-- <li><a routerLink="/notifications" class="nav-link">Notifications</a></li> -->
      </ul>
    </nav>
  </div>
  <div class="site-logout">
    <i class="fa-solid fa-power-off" (click)="logout()" style="cursor: pointer;"></i>
  </div>
</header>
