import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotificationsModule } from './modules/notifications/notifications.module';
import { PointSaleModule } from './modules/point-sale/point-sale.module';
import { SettingsModule } from './modules/settings/settings.module';
import { UsersModule } from './modules/users/users.module';
import { AuthModule } from './modules/auth/auth.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GlobalService } from './services/global/global.service';
import { AdminGuard } from './modules/shared/guards/admin-guard/admin-guard.guard';
import { ManagerGuard } from './modules/shared/guards/customer-guard/customer-guard.guard';
import { ConnectedGuard } from './modules/shared/guards/connected-guard/connected.guard';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './modules/shared/interceptor/toker-interceptor.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AlreadyConnectGuard } from './modules/shared/guards/already-connected/already-connect.guard';
import { CombineGuard } from './modules/shared/guards/combine-guard/combine.guard';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NotificationsModule,
    PointSaleModule,
    SettingsModule,
    UsersModule,
    AuthModule,
    ReactiveFormsModule,
    FormsModule,
    SweetAlert2Module.forRoot()
  ],
  providers: [
    GlobalService,
     AdminGuard,
     ManagerGuard,
     ConnectedGuard,
     AlreadyConnectGuard,
     CombineGuard,
     { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
     { provide: LocationStrategy, useClass: HashLocationStrategy },

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
