import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedRoutingModule } from './shared-routing.module';
import { HeaderLayoutComponent } from './layouts/header-layout/header-layout.component';
import { FormHourPointSaleComponent } from './forms/form-hour-point-sale/form-hour-point-sale.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    HeaderLayoutComponent,
    FormHourPointSaleComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  exports: [
    HeaderLayoutComponent,
    FormHourPointSaleComponent
  ],
})
export class SharedModule { }
