import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

const routes: Routes = [

  {
    path: 'point-sale',
    loadChildren: () => import("../point-sale/point-sale.module").then(module => module.PointSaleModule)
  },
  {
    path: 'users-permission',
    loadChildren: () => import("../users/users.module").then(module => module.UsersModule)
  },
  {
    path: 'settings',
    loadChildren: () => import("../settings/settings.module").then(module => module.SettingsModule)
  },
  // {
  //   path: 'notifications',
  //   loadChildren: () => import("../notifications/notifications.module").then(module => module.NotificationsModule)
  // }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class SharedRoutingModule {}
