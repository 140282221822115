import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';
import { I_User } from '../../shared/interfaces/user.interface';
import { PointSaleService } from 'src/app/services/point-sale/point-sale.service';


@Component({
  selector: 'app-permission-page',
  templateUrl: './permission-page.component.html',
  styleUrls: ['./permission-page.component.scss']
})
export class PermissionPageComponent implements OnInit {

  users: I_User[] = [];

  constructor(
    private userService: UserService,
    private pointSaleService: PointSaleService
  ) {
  }

  ngOnInit(): void {
      this.userService.getAllUser();
      this.pointSaleService.getAllPointSale();
      this.userService.users$.subscribe(value => {
        this.users = value;
      })
  }

  updateUser(user: I_User): void {
    this.userService.user$.next(user);
  }

  deleteUser(id: number): void {
    this.userService.deleteUser(id);
  }

}
