export enum E_TimeZone {
  MINUS12 = "-12",
  MINUS11 = "-11",
  MINUS10 = "-10",
  MINUS9 = "-09",
  MINUS8 = "-08",
  MINUS7 = "-07",
  MINUS6 = "-06",
  MINUS5 = "-05",
  MINUS4 = "-04",
  MINUS3 = "-03",
  MINUS2 = "-02",
  MINUS1 = "-01",
  ZERO = "+00",
  PLUS1 = "+01",
  PLUS2 = "+02",
  PLUS3 = "+03",
  PLUS4 = "+04",
  PLUS5 = "+05",
  PLUS6 = "+06",
  PLUS7 = "+07",
  PLUS8 = "+08",
  PLUS9 = "+09",
  PLUS10 = "+10",
  PLUS11 = "+11",
  PLUS12 = "+12"
}


export enum E_Zone {
  EUPARIS = "Eu/Paris",
  EUWEST = "Eu West",
  EUEAST = "Eu East",
  CHINA = "China",
  JAPAN = "Japan"
}
