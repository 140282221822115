import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { PointsOfSaleComponent } from './points-of-sale/points-of-sale.component';
import { DetailPointSaleComponent } from './detail-point-sale/detail-point-sale.component';
import { ConnectedGuard } from '../shared/guards/connected-guard/connected.guard';
import { AdminGuard } from '../shared/guards/admin-guard/admin-guard.guard';
import { ManagerGuard } from '../shared/guards/customer-guard/customer-guard.guard';
import { CombineGuard } from '../shared/guards/combine-guard/combine.guard';

export const routes: Routes = [
  {
    path: '',
    canActivate: [ConnectedGuard],
    children: [
      {
        path: '',
        component: PointsOfSaleComponent,
        canActivate: [CombineGuard]
      },
      {
        path: ":imei",
        component: DetailPointSaleComponent,
      }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class PointSaleRoutingModule {}

