<app-header></app-header>
<div class="page">
    <h1 class="titre mt-4 mb-4">
        List of point of sale
    </h1>
    <div class="scrollable-div">
        <table class="table tb-pers mt-3">
            <tbody>
                <tr *ngFor="let pointSale of pointSales">
                    <td>Name : {{pointSale.name}}</td>
                    <td>Imei : {{pointSale.imei}}</td>
                    <td>Creation date : {{pointSale.createdAt | date : 'dd/MM/yyyy'}}</td>
                    <td>Number of power supplies : {{pointSale.alimentations.length}}</td>
                    <td>
                        <div [ngClass]="pointSale?.data?.Online === 1 ? 'cnx' :  'cnxOff'"></div>
                    </td>
                    <td>
                        <a role="button" (click)="updatePointSale(pointSale)"><i class="fa-solid fa-pen espace"></i></a>
                        <a role="button" (click)="openModalAlimentation(pointSale)" ><i class="fa-solid fa-plug espace"></i></a>
                        <a role="button" (click)="updateHourPointSale(pointSale)"><i class="fa-regular fa-calendar espace"></i></a>
                        <a role="button" (click)="deletePointSale(pointSale.id)"><i class="fa-solid fa-trash espace"></i></a>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>

    <div class="bt mt-5">
        <button type="button" class="btn btn-fh add" role="button" (click)="openCreationForm()">Add</button>
    </div>


    <!-- Modals calendars -->
    <!-- <div class="modal fade" id="CalendarSettings" aria-hidden="true" aria-labelledby="CalendarSettingsLabel" tabindex="-1">
        <app-form-hour-point-sale [dashboard]="false"></app-form-hour-point-sale>
    </div> -->
</div>
