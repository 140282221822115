<app-header></app-header>
<div class="page-x d-flex align-items-center  flex-column">
    <div class="row">
        <div class="col-lg-8 col-md-8 col-sm-12 d-flex justify-content-center">
            <div class="card mt-3" style="width: 100%;">
                <div class="card-body">
                    <div class="card-title card-titl d-flex space-between">
                        <div class="d-flex align-items-center justify-content-center">
                            <div [ngClass]="pointSale?.data?.Online === 1 ? 'cnx' :  'cnxOff'" style="pointer-events: none"></div>
                            <div class="d-sale">{{pointSale?.data?.Online === 1 ? "ONLINE" : "OFFLINE"}}</div>
                        </div>
                        <div class="titre">{{pointSale?.name}}</div>
                        <!-- <div>
                            <i class="fa-solid fa-wifi"></i> <span>{{pointSale?.data?.Operateur ?? ""}}</span>
                        </div> -->
                        <div>
                          <app-wifi-signal [value]="pointSale?.data?.Rssi" [operateur]="pointSale?.data?.Operateur!"></app-wifi-signal>
                        </div>
                    </div>
                    <div class="card-text">
                        <div class="row">
                            <div class="col-lg-4 col-md-4 col-sm-12 mt-3 xcenter">
                                <div class="power">
                                    <div [ngClass]="pointSale?.data?.OnOff === 1 ? 'icone-on' :  'icone'" style="cursor: pointer;" (click)="changePowerPointSale()">
                                        <i class="fa-solid fa-power-off"></i>
                                    </div>
                                    <div class="po">
                                        {{ pointSale?.data?.OnOff === 1 ? "POS Power on" : "POS Power off"}}
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-8 col-sm-12 ctr">
                                <div class="txt">
                                    <i class="fa-solid fa-gear esp" style="cursor: pointer;" *ngIf="isAdmin" (click)="updateHourPointSale()"></i> <span class="pl-3">Point of sale hours: {{hourPointSale}}</span>
                                </div>
                                <div class="mb-3 mt-3">
                                    <div class="d-flex">
                                        <div class="p-powe">Total visitor numbers:</div><div>{{totalVisitors}}</div>
                                    </div>
                                    <div class="d-flex">
                                        <div class="p-powe">Main index:</div><div>{{totalIndex}} kWh</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 bd">
            <div class="card mt-3" style="width: 100%;">
                <div class="card-body">
                    <div class="card-title text-center">
                        <div class="titre">
                            POS ENERGY
                            <span class="ab">                        
                                <i class="fa-solid fa-download esp" (click)="downloadExcel()" style="cursor: pointer;"></i>
                            </span>
                        </div>
                    </div>
                    <div class="card-text cent">
                        <div class="d-flex">
                            <div class="p-power">Total Power:</div><div>{{totalPower}} W</div>
                        </div>
                        <div class="d-flex">
                            <div class="p-power">Previous Power:</div><div>{{pointSale?.previousConsumption}} W</div>
                        </div>
                        <div class="d-flex">
                            <div class="p-power">Max Voltage:</div><div>{{maxVoltage}} V</div>
                        </div>
                        <div class="d-flex">
                            <div class="p-power">Max Temp:</div><div>{{maxTemp}} °C</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3 mb-3">
        <div class="col-12 bg-dark d-flex align-items-center pp">
            <div [ngClass]="alimentation?.data?.Power && alimentation?.data?.Power ===  '0' ? 'cnx' : 'cnxOff'" (click)="changePowerAlimentation()" [style.pointerEvents]="pointSale?.data?.OnOff === 0 ? 'none' : 'auto'"></div>
            <div class="d-sale">Main power on</div>
            <div class="d-flex m-auto">
                <div class="ml-3 mmr" style="cursor: pointer" *ngIf="minusAlimentationEnable">
                    <i class="fa-solid fa-arrow-left" (click)="minusAlimentation()"></i>
                </div>
                <div>{{alimentation?.name}}</div>
                <div class="ml-3 mml" style="cursor: pointer" *ngIf="plusAlimentationEnable">
                    <i class="fa-solid fa-arrow-right" (click)="plusAlimentation()"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-lg-4 col-md-4 col-sm-12 bd">
            <div class="card mt-3" style="width: 100%;">
                <div class="card-body card-bd">
                    <div class="card-title text-center">
                        <div class="titre">LIGHTING</div>
                    </div>
                    <div class="line"></div>
                    <div class="card-text">
                        <div class="d-flex mt-1 range">
                            <label class="form-label pr-3">Lights 1</label>
                            <input type="range" [value]="consign1" class="form-range pr-3" min="0" max="100" step="1" (change)="getRangeValue(1,$event)" [disabled]="disableConsigne">
                            <span class="pr-3">{{consign1Haut}}%</span>
                            <button class="btn btn-fhOn" (click)="autoPresetConsign(1)" [disabled]="disableConsigne">Auto</button>
                        </div>
                        <div class="d-flex mt-1">
                            <label class="form-label pr-3">Lights 2</label>
                            <input type="range" [value]="consign2" class="form-range pr-3" min="0" max="100" step="1" (change)="getRangeValue(2,$event)" [disabled]="disableConsigne">
                            <span class="pr-3">{{consign2Haut}}%</span>
                            <button class="btn btn-fhOn" (click)="autoPresetConsign(2)" [disabled]="disableConsigne">Auto</button>
                        </div>
                        <div class="d-flex mt-1">
                            <label class="form-label pr-3">Lights 3</label>
                            <input type="range" [value]="consign3" class="form-range pr-3" min="0" max="100" step="1" (change)="getRangeValue(3,$event)" [disabled]="disableConsigne">
                            <span class="pr-3">{{consign3Haut}}%</span>
                            <button class="btn btn-fhOn" (click)="autoPresetConsign(3)" [disabled]="disableConsigne">Auto</button>
                        </div>
                        <div class="d-flex mt-1">
                            <label class="form-label pr-3">Lights 4</label>
                            <span class="pr-3">On/Off</span>
                            <label class="switch">
                                <input type="checkbox" [checked]="onOffAlimentationBool" [disabled]="disableConsigne" (change)="changeOnOff($event)">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 bd">
            <div class="card mt-3" style="width: 100%;">
                <div class="card-body card-bd">
                    <div class="card-title text-center">
                        <div class="titre">VISITORS</div>
                    </div>
                    <div class="line"></div>
                    <div class="card-text d-flex space-between">
                        <div class="mt-3 mb-3 text-center">
                            <i [ngClass]="alimentation?.data?.Detect1 === 1 ? 'fa fa-male' : 'fa fa-male male'"></i> <br>
                            {{alimentation?.data?.Distance1 ?? 0}} cm
                        </div>
                        <div class="text-content">
                            <div class="text-center">
                                <p>Visitor numbers</p>
                                <p><b>{{alimentation?.data?.CompteurVisite ?? 0}} people</b></p>
                            </div>
                            <div class="text-center">
                                <p>Total Visiting time</p>
                                <p><b>{{visitingTimeAlimentation}}</b></p>
                            </div>
                        </div>
                        <div class="mt-3 mb-3 text-center">
                            <i [ngClass]="alimentation?.data?.Detect2 === 1 ? 'fa fa-male' : 'fa fa-male male'"></i> <br>
                            {{alimentation?.data?.Distance2 ?? 0}} cm
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 bd">
            <div class="card mt-3" style="width: 100%;">
                <div class="card-body card-bd">
                    <div class="card-title text-center">
                        <div class="titre">ENERGY</div>
                    </div>
                    <div class="line"></div>
                    <div class="card-text cent">
                        <div class="d-flex">
                            <div class="p-power">Consumption:</div><div>{{alimentation?.data?.PuissanceCourante ?? 0}} W</div>
                        </div>
                        <div class="d-flex">
                            <div class="p-power">Index:</div><div>{{alimentation?.data?.CompteurWh ?? 0}} kWh</div>
                        </div>
                        <div class="d-flex">
                            <div class="p-power">Temperature:</div><div>{{alimentation?.data?.Temperature ?? 0}} °C</div>
                        </div>
                        <div class="d-flex">
                            <div class="p-power">Max Voltage:</div><div>{{alimentation?.data?.TensionMax ?? 0}} V</div>
                        </div>
                        <div class="d-flex">
                            <div class="p-power">Current Voltage:</div><div>{{alimentation?.data?.TensionCourante ?? 0}} V</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


