import { Component, OnDestroy, OnInit } from '@angular/core';
import { PointSaleService } from 'src/app/services/point-sale/point-sale.service';
import { I_PointSale } from '../../shared/interfaces/point-sale.interface';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormPointSaleComponent } from '../forms/form-point-sale/form-point-sale.component';
import { FormUpdatePointSaleComponent } from '../forms/form-update-point-sale/form-update-point-sale.component';
import { Subscription} from 'rxjs';
import { FormAlimentationComponent } from '../forms/form-alimentation/form-alimentation.component';
import { FormHourPointSaleComponent } from '../../shared/forms/form-hour-point-sale/form-hour-point-sale.component';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { I_WSType1 } from '../../shared/interfaces/websocket.interface';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit, OnDestroy{
  private unsubscribePdv$: Subscription = new Subscription();
  private unsubscribeSocketPdv$: Subscription = new Subscription();

  pointSales: I_PointSale[] = []
  constructor(
    private pointSaleService: PointSaleService,
    private modalService: NgbModal,
    private webSocketService: WebsocketService,
  ) {}

  ngOnInit(): void {
      this.pointSaleService.pointSales$.next([]);
      this.pointSaleService.getAllPointSale();
      this.unsubscribePdv$ = this.pointSaleService.pointSales$.subscribe(value => {
        if(value.length > 0) this.pointSales = value;
      })

      this.unsubscribeSocketPdv$ = this.webSocketService.pointSaleSocket$.subscribe(async(value) => {
        if(value !== null) await this.setPointSaleValue(value as I_WSType1);
      });

  }

  ngOnDestroy(): void {
      this.unsubscribePdv$.unsubscribe();
      this.unsubscribeSocketPdv$.unsubscribe();
  }


  updatePointSale(pointSale: I_PointSale): void {
    if(pointSale.data && pointSale.data.Online === 1) {
      this.pointSaleService.pointSale$.next(pointSale);
      const href = this.modalService.open(FormUpdatePointSaleComponent);
    }
    else {
      Swal.fire({
        title: 'Point of sale offline',
        toast: true,
        timer: 3000,
        position: "top-right",
        showConfirmButton: false,
        background: "#f72020",
        color: "#fff"
      });
    }
  }

  updateHourPointSale(pointSale: I_PointSale): void {
    if(pointSale.data && pointSale.data.Online === 1) {
      this.pointSaleService.pointSale$.next(pointSale);
      const href = this.modalService.open(FormHourPointSaleComponent);
    }
    else {
      Swal.fire({
        title: 'Point of sale offline',
        toast: true,
        timer: 3000,
        position: "top-right",
        showConfirmButton: false,
        background: "#f72020",
        color: "#fff"
      });
    }

  }

  deletePointSale(id: number): void {
    Swal.fire({
      title: 'Delete point sale',
      text: "Are you sure you want to delete the point sale?",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Delete',
      reverseButtons: true,
      customClass: {}
    }).then((result) => {
      if (result.isConfirmed) {
        this.pointSaleService.deletePointSale(id);
      }
    })
  }

  openCreationForm(): void {
    const href = this.modalService.open(FormPointSaleComponent);
  }

  openModalAlimentation(pointSale: I_PointSale): void {
    if(pointSale.data &&  pointSale.data.Online === 1) {
      this.pointSaleService.pointSale$.next(pointSale);
      const href = this.modalService.open(FormAlimentationComponent, {centered: true});
      href.componentInstance.pointSale = pointSale;
    }
    else {
      Swal.fire({
        title: 'Point of sale offline',
        toast: true,
        timer: 3000,
        position: "top-right",
        showConfirmButton: false,
        background: "#f72020",
        color: "#fff"
      });
    }
  }

  setPointSaleValue(value: I_WSType1): void {
    const pointSaleIndex = this.pointSales.findIndex(pdv => pdv.imei === value.imei);
    if(pointSaleIndex !== -1) this.pointSales[pointSaleIndex].data = value;
  }

}


