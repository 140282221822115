import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { I_PointSale } from 'src/app/modules/shared/interfaces/point-sale.interface';
import { HttpParams } from '@angular/common/http';
import { I_AlimentationPointSale } from 'src/app/modules/shared/interfaces/alimentation.interface';
import { AlimentationCreationUpdateDto, ArrayCreationAlimentationDto, LastConsigneDto, PointSaleCreationUpdateDto, PointSaleHoraireDto } from 'src/app/modules/shared/dto/point-sale.dto';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { E_PermissionUser } from 'src/app/modules/shared/enums/permissionUser.enum';

@Injectable({
  providedIn: 'root'
})
export class PointSaleService {

  private urlPointSale = environment.url+"point-sale";
  selectSearchZone$ = new BehaviorSubject<string | null>(null);
  inputSearchZone$ = new BehaviorSubject<string | null>(null);
  pointSales$ = new BehaviorSubject<I_PointSale[]>([]);
  pointSalesWithParams$ = new BehaviorSubject<I_PointSale[]>([]);
  pointSale$ = new BehaviorSubject<I_PointSale | null>(null);
  alimentations$ = new BehaviorSubject<I_AlimentationPointSale[] | null>(null);
  alimentation$ = new BehaviorSubject<I_AlimentationPointSale | null>(null);
  hourPointSale$ = new BehaviorSubject<{hour: string, pointSale: I_PointSale} | null>(null);
  totalPointSale$ = new BehaviorSubject<number>(0);
  endCreationPointSale$ = new BehaviorSubject<{status: boolean, pointSale: I_PointSale}|null>(null);
  archiveAlimentation$ = new BehaviorSubject<any[]>([])
  constructor(private apiService: ApiService, private router: Router) {
      this.inputSearchZone$.pipe(debounceTime(500), distinctUntilChanged()).subscribe(value => {
        if(value !== null) this.getAllPointSaleWithParams();
      })
   }

   /**
    * Fonction à appeler pour avoir la liste de tous les points de vente
    */
  getAllPointSale(): void {
    this.apiService.get(this.urlPointSale).subscribe(result => {
      if(result.status === "OK") {
        this.pointSales$.next(result.data.pointSales);
        this.totalPointSale$.next(result.data.total)
      }
    })
  }

   /**
    * Fonction à appeler pour avoir la liste de tous les points de vente
    */
  getAllPointSaleWithParams(): void {
    let httpParams: HttpParams = new HttpParams();
    if(this.selectSearchZone$.value !== null) httpParams = httpParams.append('zone', this.selectSearchZone$.value);
    if(this.inputSearchZone$.value !== null) httpParams = httpParams.append('name', this.inputSearchZone$.value);
    console.log(httpParams)
    this.apiService.get(this.urlPointSale+"/params/parameter", {params: httpParams}).subscribe(result => {
      if(result.status === "OK") {
        this.pointSalesWithParams$.next(result.data.pointSales);
        this.totalPointSale$.next(result.data.total)
      }
    })
  }

  /**
   * Fonction à appeler pour avoir un point de vente par son imei
   * @param imei
   */
  getPointSaleByImei(imei: string): void {
    this.apiService.get(this.urlPointSale+`/pdv/${imei}`).subscribe(result => {
      if(result.status === "OK")  this.pointSale$.next(result.data);
      else if(result.status === "ERROR") {
        if(localStorage.getItem('role_user_api') === E_PermissionUser.ADM || localStorage.getItem('role_user_api') === E_PermissionUser.MAN) {
          this.router.navigateByUrl('/point-sale');
          Swal.fire({
            title: result.message,
            toast: true,
            timer: 2000,
            position: "top-right",
            showConfirmButton: false,
            background: "#f72020",
            color: "#fff"
          })
        }
        else if(localStorage.getItem('role_user_api') === E_PermissionUser.CUST) {
          const imei = localStorage.getItem('point_sale_imei');
          this.router.navigateByUrl(`/point-sale/${imei}`);
          Swal.fire({
            title: result.message,
            toast: true,
            timer: 2000,
            position: "top-right",
            showConfirmButton: false,
            background: "#f72020",
            color: "#fff"
          });
        }
      }
    })
  }

  /**
   * Fonction à appeler pour créer un point de vente
   * @param pointSaleData
   */
  createPointSale(pointSaleData: PointSaleCreationUpdateDto): void {
    this.apiService.post(this.urlPointSale, pointSaleData).subscribe(result => {
      if(result.status === "OK") {
        this.endCreationPointSale$.next({status: true, pointSale: result.data});
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#16c71c",
          color: "#fff"
        });
        this.getAllPointSale();
      }
      else if(result.status === "ERROR") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#f72020",
          color: "#fff"
        })
      }
    })
  }

  /**
   * Fonction à appeler pour mettre à jour un point de vente
   * @param id
   * @param pointSaleData
   */
  updatePointSale(id: number , pointSaleData: PointSaleCreationUpdateDto): void {
    this.apiService.put(this.urlPointSale+`/${id}`, pointSaleData).subscribe(result => {
      if(result.status === "OK") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#16c71c",
          color: "#fff"
        });
        this.getAllPointSale();
      }
      else if(result.status === "ERROR") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#f72020",
          color: "#fff"
        })
      }
    })
  }



  /**
   * Fonction pour avoir l'heure d'ouverture à afficher pour le point de vente sur le dashboard
   */
  getHourPointSale(pointSale: I_PointSale): string {
    const today = new Date().getDay();
    let hour: string = "";
    switch(today) {
      case 0:
        hour =  pointSale?.horaires.Sunday.matin + " - " + pointSale?.horaires.Sunday.soir;
        break;
      case 1:
      hour =  pointSale?.horaires.Monday.matin + " - " + pointSale?.horaires.Monday.soir;
      break;

      case 2:
      hour =  pointSale?.horaires.Tuesday.matin + " - " + pointSale?.horaires.Tuesday.soir;
      break;
      case 3:
      hour =  pointSale?.horaires.Wednesday.matin + " - " + pointSale?.horaires.Wednesday.soir;
      break;

      case 4:
      hour =  pointSale?.horaires.Thursday.matin + " - " + pointSale?.horaires.Thursday.soir;
      break;

      case 5:
      hour =  pointSale?.horaires.Friday.matin + " - " + pointSale?.horaires.Friday.soir;
      break;

      case 6:
      hour =  pointSale?.horaires.Friday.matin + " - " + pointSale?.horaires.Friday.soir;
      break;
    }
    return hour;
  }


  /**
   * Fonction à appeler pour modifier les heures d'ouverture d'un point de vente
   * @param id
   * @param hourData
   */
  updateHourPointSale(id: number,hourData: PointSaleHoraireDto): void {
    this.apiService.put(this.urlPointSale+`/${id}/horaire`, hourData).subscribe(result => {
      if(result.status === "OK") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#16c71c",
          color: "#fff"
        });
        this.getAllPointSale();
        const dataHour  = {
          hour: this.getHourPointSale(result.data),
          pointSale: result.data
        };
        this.hourPointSale$.next(dataHour);


      }
      else if(result.status === "ERROR") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#f72020",
          color: "#fff"
        })
      }
    })
  }


  /**
   * Fonction à appeler pour modifier un point de vente et créer une alimentation
   * @param id // PointSaleID
   * @param pointSaleDto
   * @param alimentationDto
   */
  updatePointSaleCreateAlimentation(id: number, pointSaleDto: PointSaleCreationUpdateDto, alimentationDto: AlimentationCreationUpdateDto): void {
    this.apiService.put(this.urlPointSale+`/${id}`, pointSaleDto).subscribe(result => {
      if(result.status === "OK") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#16c71c",
          color: "#fff"
        });
        this.apiService.post(this.urlPointSale+'/alimentation', alimentationDto).subscribe(result => {
        if(result.status === "OK") {
          Swal.fire({
            title: result.message,
            toast: true,
            timer: 2000,
            position: "top-right",
            showConfirmButton: false,
            background: "#16c71c",
            color: "#fff"
          });
          this.alimentations$.next(result.data);
          this.getAllPointSale();
          }
          else if(result.status === "ERROR") {
            Swal.fire({
              title: result.message,
              toast: true,
              timer: 2000,
              position: "top-right",
              showConfirmButton: false,
              background: "#f72020",
              color: "#fff"
            })
          }
        });
      }
      else if(result.status === "ERROR") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#f72020",
          color: "#fff"
        })
      }
    });
  }

  /**
   * Fonction à appeler pour modifier un point de vente et créer une alimentation
   * @param id // PointSaleID
   * @param pointSaleDto
   * @param idAlimentation // AlimentationID
   * @param alimentationDto
   */
  updatePointSaleUpdateAlimentation(id: number, pointSaleDto: PointSaleCreationUpdateDto, idAlimentation: number, alimentationDto: AlimentationCreationUpdateDto): void {
    this.apiService.put(this.urlPointSale+`/${id}`, pointSaleDto).subscribe(result => {
      if(result.status === "OK") {
        this.apiService.put(this.urlPointSale+`/alimentation/${idAlimentation}`, alimentationDto).subscribe(result => {
          if(result.status === "OK") {
            Swal.fire({
              title: "Update successfull",
              toast: true,
              timer: 2000,
              position: "top-right",
              showConfirmButton: false,
              background: "#16c71c",
              color: "#fff"
            });
            this.alimentations$.next(result.data);
            this.getAllPointSale();
          }
          else if(result.status === "ERROR") {
            Swal.fire({
              title: result.message,
              toast: true,
              timer: 2000,
              position: "top-right",
              showConfirmButton: false,
              background: "#f72020",
              color: "#fff"
            })
          }
        })
      }
      else if(result.status === "ERROR") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#f72020",
          color: "#fff"
        })
      }
    });
  }

  /**
   * Fonction à appeler pour supprimer un point de vente
   * @param id
   */
  deletePointSale(id: number): void {
    this.apiService.delete(this.urlPointSale+`/${id}`).subscribe(result => {
      if(result.status === "OK") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#16c71c",
          color: "#fff"
        });
        this.getAllPointSale();
      }
      else if(result.status === "ERROR") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#f72020",
          color: "#fff"
        })
      }
    })
  }

  /**
   * Fonction à appeler pour supprimer une alimentation
   * @param imei
   */
  deleteAlimentation(imei: string): void {
    this.apiService.delete(this.urlPointSale+`/delete-alimentation/${imei}`).subscribe(result => {
      if(result.status === "OK") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#16c71c",
          color: "#fff"
        });
        this.alimentations$.next(result.data)
        this.getAllPointSale();
      }
      else if(result.status === "ERROR") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2500,
          position: "top-right",
          showConfirmButton: false,
          background: "#f72020",
          color: "#fff"
        })
      }
    })
  }

  /**
   * Fonction à appeler pour modifier la dernière value d'une consigne
   */
  updateLastConsigne(id: number, lastConsigneDto: LastConsigneDto): void {
    this.apiService.put(this.urlPointSale+`/last-consigne/${id}`, lastConsigneDto).subscribe(result => {
      if(result.status === "OK") this.pointSale$.next(result.data)
    })
  }

  /**
   * Fonction à appeler pour créer une alimentation
   * @param alimentationDto
   */
  createAlimentation(alimentationDto: ArrayCreationAlimentationDto): void {
    this.apiService.post(this.urlPointSale+"/alimentation", alimentationDto).subscribe(result => {
      if(result.status === "OK") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#16c71c",
          color: "#fff"
        });
        this.getAllPointSale();
      }
      else if(result.status === "ERROR") {
        Swal.fire({
          title: result.message,
          toast: true,
          timer: 2000,
          position: "top-right",
          showConfirmButton: false,
          background: "#f72020",
          color: "#fff"
        })
      }

    })
  }

  /**
   * Fonction à appeler pour avoir les archives d'un point de vente
   * @param  imei
   */
  getArchive(imei: string): void {
    this.apiService.get(this.urlPointSale+`/archives/${imei}`).subscribe(result => {
      if(result.status ===  "OK") this.archiveAlimentation$.next(result.data);
    })
  }

}
