import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { NotificationsComponent } from './notifications/notifications.component';
import { ConnectedGuard } from '../shared/guards/connected-guard/connected.guard';
import { AdminGuard } from '../shared/guards/admin-guard/admin-guard.guard';

export const routes: Routes = [
  {
    path: '',
    component: NotificationsComponent,
    canActivate: [ConnectedGuard,AdminGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})

export class NotificationsRoutingModule {}

