
  <div class="modal-header">
      <h5 class="modal-title" id="AddSettingsLabel">Point of sale creation form</h5>
      <button type="button" class="btn-close" (click)="close()"></button>
  </div>
  <form [formGroup]="formPointSale" (submit)="createPointSale()">
    <div class="modal-body">
        <div class="form-input mb-3">
            <label class="form-label">Name of point of sale :</label>
            <input type="text" class="form-control" placeholder="Name" formControlName="name">
        </div>
        <div class="form-input mb-3">
            <label class="form-label">Imei : </label>
            <input type="text" class="form-control" placeholder="Imei" formControlName="imei">
        </div>
        <div class="form-input mb-3">
            <label class="form-label">Zone :</label>
            <select class="form-select" aria-label="Default select example" formControlName="zone">
              <option *ngFor="let zone of enumZoneValues" [value]="zone">{{zone}}</option>
            </select>
        </div>

        <div class="form-input mb-3">
            <label class="form-label">TimeZone :</label>
            <select class="form-select" aria-label="Default select example" formControlName="timezone">
                <option *ngFor="let timezone of enumTimeZoneValues" [value]="timezone">GMT {{timezone}}</option>
            </select>
        </div>
        <div class="form-input mb-3">
            <label class="form-label">Previous consumption: </label>
            <input type="text" class="form-control" placeholder="Previous consumption" formControlName="name" formControlName="previousConsumption">
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-fh" type="submit" [disabled]="!formPointSale.valid">Create</button>
    </div>
  </form>




