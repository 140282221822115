import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import {  ArrayCreationAlimentationDto } from 'src/app/modules/shared/dto/point-sale.dto';
import { I_PointSale } from 'src/app/modules/shared/interfaces/point-sale.interface';
import { PointSaleService } from 'src/app/services/point-sale/point-sale.service';

@Component({
  selector: 'app-form-alimentation',
  templateUrl: './form-alimentation.component.html',
  styleUrls: ['./form-alimentation.component.scss']
})
export class FormAlimentationComponent implements OnInit, OnDestroy{
  formAlimentation: FormGroup;
  consignValue: {canal: string, value: number}[] = [];
  consignArray: number[] = [1,2,3,4];
  creationAlimentation: boolean = false;
  consignAlimentation = {canal1: 0, canal2: 0, canal3: 0, canal4: 0, canal5:0, canal6: 0, canal7:0, canal8: 0};
  showButtonPowerSupply: boolean = true;
  lastAddress: number = 1;
  pointSale: I_PointSale | null = null;
  private unsubscribePdv$: Subscription = new Subscription();
  private unsubscribePdvCreation$: Subscription = new Subscription();

  constructor(
    private pointSaleService: PointSaleService,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal
  ){
    this.formAlimentation = this.formBuilder.group({
      alimentations: this.formBuilder.array([])
    })
  }

  ngOnInit(): void {
    this.unsubscribePdvCreation$ = this.pointSaleService.endCreationPointSale$.subscribe(value => {
      if(value !== null) this.pointSaleService.pointSale$.next(value.pointSale);
    });

    this.unsubscribePdv$ = this.pointSaleService.pointSale$.subscribe(value => {
      if(value !== null) {
        this.pointSale = value;
        if(this.pointSale.alimentations.length > 0) {
          for (const alimentation of this.pointSale.alimentations) {
            this.addAlimentation(alimentation.name, alimentation.address);
          }
        }
        else if(this.pointSale.alimentations.length === 0) {
          this.addAlimentation();
        }
      }
    })


  }

  ngOnDestroy(): void {
      this.unsubscribePdv$.unsubscribe();
      this.unsubscribePdvCreation$.unsubscribe();
  }

  get alimentations(): FormArray {
    return this.formAlimentation.get('alimentations') as FormArray;
  }


  close(): void {
    this.activeModal.close();
  }

  createAlimentationGroup(name?: string, address?: number): FormGroup {
    if(name && address) {
      return this.formBuilder.group({
        name: [name, Validators.required],
        address: [address, Validators.required]
      });
    }
    else {
      if(this.lastAddress === 1) {
        return this.formBuilder.group({
          name: ['', Validators.required],
          address: [1, Validators.required]
        });
      }
      else {
        return this.formBuilder.group({
          name: ['', Validators.required],
          address: [this.lastAddress, Validators.required]
        });
      }
    }
  }




  addAlimentation(name?: string, address?: number): void {
    if(name && address) this.alimentations.push(this.createAlimentationGroup(name, address));
    else this.alimentations.push(this.createAlimentationGroup());
    this.lastAddress = this.lastAddress + 1;
  }

  createAlimentation(): void {
    if(this.formAlimentation.valid) {
      if(this.pointSale) {
        const alimentationDto: ArrayCreationAlimentationDto = {alimentations: this.formAlimentation.value.alimentations, imei: this.pointSale.imei};
        this.pointSaleService.createAlimentation(alimentationDto);
      }
      this.close();
    }
  }


}
