<app-header></app-header>
<div class="page">
    <h1 class="titre mt-4 mb-4">
        User management & permissions
    </h1>
    <button class="btn-none m-1" data-bs-toggle="modal" href="#userModalCreation" role="button">
        <i class="fa-solid fa-plus"></i> <span>Add user</span>
    </button>
    <div class="scrollable-div">
        <table class="table tb-pers mt-3">
            <tbody>
                <tr *ngFor="let user of users">
                    <td>Name : {{user.name}}</td>
                    <td>Surname : {{user.surname}}</td>
                    <td>Email : {{user.email}}</td>
                    <td>Telephone : {{user.phone}}</td>
                    <td>Permission : {{user.permission}}</td>
                    <td>
                        <a data-bs-toggle="modal" href="#userModalUpdate" role="button" (click)="updateUser(user)"><i class="fa-solid fa-pen espace"></i></a>
                        <a role="button"><i class="fa-solid fa-trash espace" (click)="deleteUser(user.id)"></i></a>
                    </td>
                </tr>

            </tbody>
        </table>
    </div>
    <!-- Modals User -->
    <div class="modal fade" id="userModalCreation" aria-hidden="true" aria-labelledby="CreatePermissionLabel" tabindex="-1">
            <app-form-user-permission></app-form-user-permission>
    </div>
    <div class="modal fade" id="userModalUpdate" aria-hidden="true" aria-labelledby="UpdatePermissionLabel" tabindex="-1">
            <app-form-update-user-permission></app-form-update-user-permission>
    </div>
</div>
