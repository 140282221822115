import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ManagerGuard } from '../customer-guard/customer-guard.guard';
import { AdminGuard } from '../admin-guard/admin-guard.guard';

@Injectable({
  providedIn: 'root'
})
export class CombineGuard implements CanActivate {
  constructor(private managerGuard: ManagerGuard, private adminGuard: AdminGuard) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.managerGuard.canActivate(route, state) || this.adminGuard.canActivate(route, state);
  }

}
