import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SettingsRoutingModule } from './settings-routing.module';
import { SettingsComponent } from './settings/settings.component';
import { SharedModule } from '../shared/shared.module';
import { PointSaleService } from 'src/app/services/point-sale/point-sale.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormPointSaleComponent } from './forms/form-point-sale/form-point-sale.component';
import { FormUpdatePointSaleComponent } from './forms/form-update-point-sale/form-update-point-sale.component';
import { FormAlimentationComponent } from './forms/form-alimentation/form-alimentation.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    SettingsComponent,
    FormPointSaleComponent,
    FormUpdatePointSaleComponent,
    FormAlimentationComponent
  ],
  imports: [
    CommonModule,
    SettingsRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule
  ],
  providers: [PointSaleService]
})
export class SettingsModule { }
