import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PointsOfSaleComponent } from './points-of-sale/points-of-sale.component';
import { PointSaleRoutingModule } from './point-sale-routing.module';
import { SharedModule } from '../shared/shared.module';
import { PointSaleService } from 'src/app/services/point-sale/point-sale.service';
import { DetailPointSaleComponent } from './detail-point-sale/detail-point-sale.component';
import { WifiSignalComponent } from './layouts/wifi-signal/wifi-signal/wifi-signal.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';



@NgModule({
  declarations: [
    PointsOfSaleComponent,
    DetailPointSaleComponent,
    WifiSignalComponent
  ],
  imports: [
    CommonModule,
    PointSaleRoutingModule,
    SharedModule,
    NgbModule
  ],
  providers: [PointSaleService, NgbActiveModal]
})
export class PointSaleModule { }
