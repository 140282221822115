import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login/login.service';
import { LoginDto } from '../../shared/dto/login.dto';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent {
  formLogin: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService
  ) {
    this.formLogin = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", Validators.required],
    })
  }

  login() : void {
    if(this.formLogin.valid) {
      const loginDto: LoginDto = {email: this.formLogin.value.email, password: this.formLogin.value.password}
      this.loginService.login(loginDto);
    }
  }

}
