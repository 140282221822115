import { Component, OnDestroy, OnInit } from '@angular/core';
import { I_ArchiveAlimentation, I_PointSale } from '../../shared/interfaces/point-sale.interface';
import { PointSaleService } from 'src/app/services/point-sale/point-sale.service';
import { WebsocketService } from 'src/app/services/websocket/websocket.service';
import { I_AlimentationPointSale } from '../../shared/interfaces/alimentation.interface';
import { ActivatedRoute } from '@angular/router';
import { I_WSType0, I_WSType1 } from '../../shared/interfaces/websocket.interface';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { E_PermissionUser } from '../../shared/enums/permissionUser.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormHourPointSaleComponent } from '../../shared/forms/form-hour-point-sale/form-hour-point-sale.component';
@Component({
  selector: 'app-detail-point-sale',
  templateUrl: './detail-point-sale.component.html',
  styleUrls: ['./detail-point-sale.component.scss']
})
export class DetailPointSaleComponent implements OnInit, OnDestroy {
  pointSale: I_PointSale | null = null;
  minusAlimentationEnable: boolean = false;
  plusAlimentationEnable: boolean = false;
  actualAlimentationIndex: number = 0;
  alimentation: I_AlimentationPointSale | null = null;
  alimentations: I_AlimentationPointSale[] = [];
  consign1Haut: number = 0;
  consign2Haut: number = 0;
  consign3Haut: number = 0;
  consign1: number = 0;
  consign2: number = 0;
  consign3: number = 0;
  onoff: number = 0;
  totalPower: number = 0;
  maxVoltage: number = 0;
  totalVisitors: number = 0;
  totalIndex: number = 0;
  maxTemp: number = 0;
  disableConsigne: boolean = false;
  visitingTimeAlimentation: string = "";
  onOffAlimentationBool: boolean = false;
  hourPointSale: string = "";
  isAdmin: boolean = localStorage.getItem("role_user_api") === E_PermissionUser.ADM ? true : false;
  archiveAlimentation: I_ArchiveAlimentation[] = [];
  private unsubscribePdv$: Subscription = new Subscription();
  private unsubscribeAlim$: Subscription = new Subscription();
  private unsubscribeSocketAlim$: Subscription = new Subscription();
  private unsubscribeSocketPdv$: Subscription = new Subscription();
  private unsubscribeArchive$: Subscription = new Subscription();



  constructor(
    public pointSaleService: PointSaleService,
    private webSocketService: WebsocketService,
    private activeRoute: ActivatedRoute,
    private modalService: NgbModal
  ){}

  async ngOnInit(): Promise<void> {
    const imei = this.activeRoute.snapshot.params['imei'];
    this.pointSaleService.getArchive(imei);
    this.pointSaleService.getPointSaleByImei(imei)
    this.pointSaleService.alimentation$.next(null);
    this.pointSaleService.pointSale$.next(null);
    if(this.pointSaleService.hourPointSale$.subscribe(value =>  {
      if(value !== null) {
        this.hourPointSale = value.hour;
        if(this.pointSale?.horaires) this.pointSale.horaires = value.pointSale.horaires;
      }
    }))


    this.unsubscribePdv$ = this.pointSaleService.pointSale$.subscribe(value => {
      if(value !== null) {
        this.pointSale = value;
        this.hourPointSale = this.pointSaleService.getHourPointSale(value);

        if(this.alimentation !== null) {
          this.alimentations = value.alimentations;
          const alimUpdated = this.pointSale.alimentations.find(alim => alim.id === this.alimentation?.id);
          this.alimentation = alimUpdated as I_AlimentationPointSale;
          this.setAlimentation();
        }
        else {
          if(value?.alimentations && value.alimentations.length > 0) {
            this.alimentations = value.alimentations;
            this.pointSaleService.alimentation$.next(this.alimentations[0]);
            this.minusAlimentationEnable = false;
            this.getMaxVoltageMaxTemp();
            this.getTotalPowerTotalVisitorTotalIndex();
            this.manageStateConsigne();
            this.getPointSaleHourVisitor();
            if(this.alimentations.length > 1) this.plusAlimentationEnable = true;
          }
        }
      }
    })

      this.unsubscribeAlim$ = this.pointSaleService.alimentation$.subscribe(value => {
        if(value !== null && this.alimentation?.id !== value.id) {
          this.alimentation = value;
          this.setAlimentation();
        }

      });

      this.unsubscribeSocketAlim$ = this.webSocketService.alimentationSocket$.subscribe(async(value) => {
        if(value !== null) await this.getAlimentationAndChangeValue(value as I_WSType0)
      });

      this.unsubscribeSocketPdv$ = this.webSocketService.pointSaleSocket$.subscribe(async(value) => {
        if(value !== null) await this.setPointSaleValue(value as I_WSType1);
      });

      this.unsubscribeArchive$ =this.pointSaleService.archiveAlimentation$.subscribe(value => {
        if(value) this.archiveAlimentation = value;
      })
  }

  ngOnDestroy(): void {
      this.unsubscribeAlim$.unsubscribe();
      this.unsubscribePdv$.unsubscribe();
      this.unsubscribeSocketAlim$.unsubscribe();
      this.unsubscribeSocketPdv$.unsubscribe();
      this.unsubscribeArchive$.unsubscribe();
  }

  setAlimentation(): void {
    if(this.alimentation?.data) {
      this.consign1 = this.alimentation?.lastConsigne1 ?? Number(this.alimentation?.data.Consign1);
      this.consign2 = this.alimentation?.lastConsigne2 ?? Number(this.alimentation?.data.Consign2);
      this.consign3 = this.alimentation?.lastConsigne3 ?? Number(this.alimentation?.data.Consign3);
      this.consign1Haut = this.alimentation?.data.Consign1 ?? 0;
      this.consign2Haut = this.alimentation?.data.Consign2 ?? 0;
      this.consign3Haut = this.alimentation?.data.Consign3 ?? 0;
      this.onoff = Number(this.alimentation?.data.OnOff);
    }
    else {
      this.consign1 = 0;
      this.consign2 = 0;
      this.consign3 = 0;
      this.consign1Haut = 0;
      this.consign2Haut = 0;
      this.consign3Haut = 0;
      this.onoff = 0;
    }

    this.getValueOnOffAlim();
    this.manageStateConsigne();
    this.getPointSaleHourVisitor();
  }

  minusAlimentation(): void {
    this.actualAlimentationIndex = this.actualAlimentationIndex - 1;
    if(this.actualAlimentationIndex > 0) {
      // this.pointSaleService.alimentation$.next(this.alimentations[this.actualAlimentationIndex]);
      this.alimentation = this.alimentations[this.actualAlimentationIndex];
      this.setAlimentation();
      this.minusAlimentationEnable = true;
    }
    else if(this.actualAlimentationIndex === 0) {
      // this.pointSaleService.alimentation$.next(this.alimentations[this.actualAlimentationIndex]);
      this.alimentation = this.alimentations[this.actualAlimentationIndex];
      this.setAlimentation();
      this.minusAlimentationEnable = false;
    }
    this.plusAlimentationEnable = true;
  }

  plusAlimentation(): void {
    this.actualAlimentationIndex = this.actualAlimentationIndex + 1;
    if(this.actualAlimentationIndex < this.alimentations.length - 1) {
      // this.pointSaleService.alimentation$.next(this.alimentations[this.actualAlimentationIndex]);
      this.alimentation = this.alimentations[this.actualAlimentationIndex];

      this.setAlimentation();
      this.plusAlimentationEnable = true;
    }
    else if(this.actualAlimentationIndex === this.alimentations.length - 1) {
      // this.pointSaleService.alimentation$.next(this.alimentations[this.actualAlimentationIndex]);
      this.alimentation = this.alimentations[this.actualAlimentationIndex];

      this.setAlimentation();
      this.plusAlimentationEnable = false;
    }
    this.minusAlimentationEnable = true;
  }



  manageStateConsigne(): void {
    if(this.alimentation?.data) {
      if(this.alimentation?.data.Power === '0' && this.pointSale?.data.OnOff === 1) this.disableConsigne = false;
      else this.disableConsigne = true;
    }

  }

  async getAlimentationAndChangeValue(data: I_WSType0): Promise<void> {
    const indexAlim = this.alimentations.findIndex(alim => alim.address === data.Address && alim.imei === data.imei);
    if(indexAlim !== -1) {
      if(this.alimentations[indexAlim].data) this.alimentations[indexAlim].data = data;
      this.getMaxVoltageMaxTemp();
      this.getTotalPowerTotalVisitorTotalIndex();
      if(data.Address === this.alimentation?.address && data.imei === this.alimentation.imei) {
        this.onoff = Number(this.alimentations[indexAlim]?.data.OnOff);
        this.manageStateConsigne();
        this.getPointSaleHourVisitor();
        this.getValueOnOffAlim();
        this.consign1Haut = this.alimentations[indexAlim]?.data.Consign1 ?? 0;
        this.consign2Haut = this.alimentations[indexAlim]?.data.Consign2 ?? 0;
        this.consign3Haut = this.alimentations[indexAlim]?.data.Consign3 ?? 0;
      }
    }

  }

  async setPointSaleValue(data: I_WSType1): Promise<void> {
    if(this.pointSale && this.pointSale.imei === data.imei) this.pointSale.data = data ;
    this.manageStateConsigne();
  }

  getPointSaleHourVisitor(): void {
    if(this.alimentation?.data) {
      const hour: number = Math.floor(Number(this.alimentation?.data.VisitTime) / 60);
      const minutes: number = Number(this.alimentation?.data.VisitTime) % 60;
      if(minutes < 10) this.visitingTimeAlimentation = hour+`h0${minutes}`;
      else this.visitingTimeAlimentation = hour+`h${minutes}`;
    }
  }


  getRangeValue(consign: number,event: any): void {
    const value = Number(event.target.value);
    if(this.alimentation) {
      switch(consign) {
        case 1:
          this.consign1 = value;
          this.sendMessageToWs(
            this.alimentation?.imei ?? "",
            this.alimentation?.address ?? 0,
            Number(this.alimentation?.data?.Power),
            this.onoff,
            value,
            this.consign2 ?? 100,
            this.consign3 ?? 100,
            0);
            this.pointSaleService.updateLastConsigne(Number(this.alimentation?.id), {consigne: 1, value: value, imei: this.alimentation?.imei});
          break;
        case 2:
          this.consign2 = value;
          this.consign2Haut = value;
          this.sendMessageToWs(
            this.alimentation?.imei ?? "",
            this.alimentation?.address ?? 0,
            Number(this.alimentation?.data?.Power),
            this.onoff,
            this.consign1 ?? 100,
            value,
            this.consign3 ?? 100,
            1);
            this.pointSaleService.updateLastConsigne(Number(this.alimentation?.id), {consigne: 2, value: value, imei: this.alimentation?.imei});
          break;
        case 3:
          this.consign3 = value;
          this.consign3Haut = value;
          this.sendMessageToWs(
            this.alimentation?.imei ?? "",
            this.alimentation?.address ?? 0,
            Number(this.alimentation?.data?.Power),
            this.onoff,
            this.consign1 ?? 100,
            this.consign2 ?? 100,
            value,
            2);
            this.pointSaleService.updateLastConsigne(Number(this.alimentation?.id), {consigne: 3, value: value, imei: this.alimentation?.imei});
          break;
      }
    }

  }

  changeOnOff(event: any): void {
    const onOffValue = event.target.checked  ? 1 : 0;
    this.sendMessageToWs(
      this.alimentation?.imei ?? "",
      this.alimentation?.address ?? 0,
      Number(this.alimentation?.data?.Power),
      onOffValue,
      this.consign1 ?? 100,
      this.consign2 ?? 100,
      this.consign3 ?? 100,
      6)
  }

  autoPresetConsign(consign: number): void {
    switch(consign) {
      case 1:
        this.sendMessageToWs(
          this.alimentation?.imei ?? "",
          this.alimentation?.address ?? 0,
          Number(this.alimentation?.data?.Power),
          this.onoff,
          this.alimentation?.consign1Haut ?? 100,
          this.alimentation?.consign2Haut ?? 100,
          this.alimentation?.consign3Haut ?? 100,
          3)
        break;
      case 2:
        this.sendMessageToWs(
          this.alimentation?.imei ?? "",
          this.alimentation?.address ?? 0,
          Number(this.alimentation?.data?.Power),
          this.onoff,
          this.alimentation?.consign1Haut ?? 100,
          this.alimentation?.consign2Haut ?? 100,
          this.alimentation?.consign3Haut ?? 100,
          4)
        break;
      case 3:
        this.sendMessageToWs(
          this.alimentation?.imei ?? "",
          this.alimentation?.address ?? 0,
          Number(this.alimentation?.data?.Power),
          this.onoff,
          this.alimentation?.consign1Haut ?? 100,
          this.alimentation?.consign2Haut ?? 100,
          this.alimentation?.consign3Haut ?? 100,
          5)
        break;
    }
  }

  sendMessageToWs(imei: string, address: number, power: number, onoff: number, consign1: number, consign2: number, consign3: number, preset: number): void {
    this.webSocketService.sendMessasge('etat', {
      imei: imei,
      address: address.toString(),
      power: power,
      onoff: onoff,
      consigne1: consign1,
      consigne2: consign2,
      consigne3: consign3,
      preset: preset
    });
  }

  changePowerAlimentation(): void {
    switch(Number(this.alimentation?.data?.Power)) {
      case 1 :
        this.sendMessageToWs(
          this.alimentation?.imei ?? "",
          this.alimentation?.address ?? 0,
          0,
          this.onoff,
          this.alimentation?.consign1Haut ?? 100,
          this.alimentation?.consign2Haut ?? 100,
          this.alimentation?.consign3Haut ?? 100,
          6);
          break;
      case 0 :
          this.sendMessageToWs(
            this.alimentation?.imei ?? "",
            this.alimentation?.address ?? 0,
            1,
            this.onoff,
            this.alimentation?.consign1Haut ?? 100,
            this.alimentation?.consign2Haut ?? 100,
            this.alimentation?.consign3Haut ?? 100,
            6);
            break;
    }
  }

  getMaxVoltageMaxTemp(): void {
    if(this.alimentation?.data) {
      this.maxVoltage = this.alimentations[0].data.TensionMax;
      this.maxTemp = this.alimentations[0].data.Temperature;
      if(this.alimentations.length > 1) {
        for (let i = 1; i < this.alimentations.length; i++) {
          if(this.alimentations[i].data !== null) {
            if(this.alimentations[i].data.TensionMax > this.maxVoltage) this.maxVoltage = this.alimentations[i].data.TensionMax;
            if(this.alimentations[i].data.Temperature > this.maxTemp) this.maxTemp = this.alimentations[i].data.Temperature;
          }
        }
      }
    }
  }

  getTotalPowerTotalVisitorTotalIndex(): void {
    if(this.alimentations.length === 1) {
      if(this.alimentation?.data) {
        this.totalPower = this.alimentations[0].data.PuissanceCourante;
        this.totalIndex = this.alimentations[0].data.CompteurWh;
        this.totalVisitors = this.alimentations[0].data.CompteurVisite;
      }

    }
    else if(this.alimentations.length > 1) {
    let power:number = 0;
    let visitors: number = 0;
    let index : number = 0;
      for (const alim of this.alimentations) {
        if(alim.data) {
          power = power + alim.data.PuissanceCourante;
          visitors = visitors + alim.data.CompteurVisite;
          index = index + alim.data.CompteurWh;
        }

      }
      this.totalPower = power;
      this.totalIndex = index;
      this.totalVisitors = visitors;
    }
  }

  changePowerPointSale(): void {
    this.sendMessageToWs(
      this.alimentation?.imei ?? "",
      this.alimentation?.address ?? 0,
      3,
      this.onoff,
      this.alimentation?.consign1Haut ?? 100,
      this.alimentation?.consign2Haut ?? 100,
      this.alimentation?.consign3Haut ?? 100,
      6);
  }

  getValueOnOffAlim(): void {
    if(this.onoff === 1) this.onOffAlimentationBool = true;
    else this.onOffAlimentationBool = false;
  }

  updateHourPointSale(): void {
      this.pointSaleService.pointSale$.next(this.pointSale);
      const href = this.modalService.open(FormHourPointSaleComponent);
  }

  parseDateForExcel(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${day}/${month}/${year} - ${hours}:${minutes}`;
  }

  async downloadExcel(): Promise<void> {
    let fileName: string = "";
    if(this.pointSale) fileName = this.pointSale.name;
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(this.pointSale?.name);

    // Ajouter des en-têtes de colonne
    worksheet.columns = [
      { header: 'Alimentation', key: 'name', width: 30 },
      { header: 'Adress', key: 'adress', width: 20 },
      { header: 'Power', key: 'power', width: 20 },
      { header: 'On/Off', key: 'onoff', width: 20 },
      { header: 'Consign 1', key: 'consign1', width: 20 },
      { header: 'Consign 2', key: 'consign2', width: 20 },
      { header: 'Consign 3', key: 'consign3', width: 20 },
      { header: 'Compteur visite', key: 'visit', width: 20 },
      { header: 'Visit time', key: 'visitTime', width: 20 },
      { header: 'Temperature', key: 'temp', width: 20 },
      { header: 'Tension courante', key: 'tension', width: 20 },
      { header: 'Tension max', key: 'tensionMax', width: 20 },
      { header: 'Puissance courante', key: 'consumption', width: 20 },
      { header: 'Compteur Wh', key: 'compteur', width: 20 },
      { header: 'Timestamp', key: 'date', width: 20 },
      { header: 'Total Power', key: 'totalPower', width: 40},
      { header: 'Total Visitors', key: 'visitors', width: 40},
      { header: 'Max Voltage', key: 'voltage', width: 40},
      { header: 'Max Temperature', key: 'maxTemp', width: 40},
      { header: 'Main Index', key: 'mainIndex', width: 40},
      { header: 'Previous Consumption', key: 'previousConsumption', width: 40},
      { header: 'Power Point Sale', key: 'mainPower', width: 30},
      { header: 'Point Sale online', key: 'online', width: 30},




      // ... autres colonnes
    ];

    let rows = [];
    for (const archive of this.archiveAlimentation) {
      const row = {
        name: archive.name,
        adress: archive.address,
        power: archive.power,
        onoff: archive.onOff,
        consign1: archive.consign1,
        consign2: archive.consign2,
        consign3: archive.consign3,
        visit: archive.compteurVisite,
        visitTime: archive.visitTime,
        temp: archive.temperature,
        tension: archive.tensionCourante,
        tensionMax: archive.tensionMax,
        consumption: archive.consumption,
        compteur: archive.compteurWh,
        date: this.parseDateForExcel(new Date(archive.date)),
        totalPower: archive.totalPower,
        visitors: archive.totalVisitors,
        mainIndex: archive.mainIndex,
        voltage: archive.maxVoltage,
        maxTemp: archive.maxTemperature,
        previousConsumption: archive.previousConsumption,
        mainPower: archive.mainPower,
        online: archive.onlinePointSale
      };
      rows.push(row);
    }

    worksheet.addRows(rows)

    // Formatage du fichier Excel (facultatif)
    // ...

    await this.generateAndSaveExcel(workbook, fileName);
  }

  async generateAndSaveExcel(workbook: ExcelJS.Workbook, fileName: string) {
    try {
      const buffer = await workbook.xlsx.writeBuffer(); // Wait for the promise to resolve
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, fileName);
    } catch (error) {
      console.error('Error generating Excel file:', error);
    }
  }


}


