import { Component, OnInit  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PointSaleCreationUpdateDto } from 'src/app/modules/shared/dto/point-sale.dto';
import { E_TimeZone, E_Zone } from 'src/app/modules/shared/enums/point-sale.enum';
import { GlobalService } from 'src/app/services/global/global.service';
import { PointSaleService } from 'src/app/services/point-sale/point-sale.service';
import { FormAlimentationComponent } from '../form-alimentation/form-alimentation.component';


@Component({
  selector: 'app-form-point-sale',
  templateUrl: './form-point-sale.component.html',
  styleUrls: ['./form-point-sale.component.scss']
})
export class FormPointSaleComponent implements OnInit{

  formPointSale: FormGroup;
  enumZoneEntries = Object.entries(E_Zone);
  enumZoneValues = Object.values(E_Zone);
  enumTimeZoneEntries = Object.entries(E_TimeZone);
  enumTimeZoneValues = Object.values(E_TimeZone);
  finishCreationPointSale: boolean = false;


  constructor(
    private pointSaleService: PointSaleService,
    private formBuilder: FormBuilder,
    private globalService: GlobalService,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) {
    this.formPointSale = this.formBuilder.group({
      name: ["", Validators.required],
      imei: ["", Validators.required],
      zone: [E_Zone.EUPARIS, Validators.required],
      timezone: [E_TimeZone.ZERO, Validators.required],
      previousConsumption: [0, Validators.required],
    })
  }

  ngOnInit(): void {

  }

  close(): void {
    this.activeModal.close();
  }

  createPointSale(): void {
    if(this.formPointSale.valid) {
      const pointSaleData: PointSaleCreationUpdateDto = {
        name: this.formPointSale.value.name,
        imei: this.formPointSale.value.imei,
        zone: this.globalService.getEnumKeyFromValue(this.enumZoneEntries,this.formPointSale.value.zone),
        timezone: this.globalService.getEnumKeyFromValue(this.enumTimeZoneEntries,this.formPointSale.value.timezone),
        previousConsumption: this.formPointSale.value.previousConsumption,
      }
      this.pointSaleService.createPointSale(pointSaleData);
      this.close();
      this.formPointSale.reset({
        zone: E_Zone.EUPARIS,
        timezone: E_TimeZone.ZERO,
        previousConsumption: 0
      });
      this.modalService.open(FormAlimentationComponent);
    }
  }





}
