import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, throwError } from 'rxjs';
import { LoginService } from 'src/app/services/login/login.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    public loginService: LoginService,
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Replace with your logic to get the authorization token
    const authToken = localStorage.getItem('token_auth_api');

    // Check if token exists and the request url requires authorization
    if (authToken && !req.url.includes('auth') /* Assuming login endpoint doesn't need token */) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', `Bearer ${authToken}`)
      });
      return next.handle(authReq).pipe(
        catchError((error) => this.handleError(error)) // Bind `this` to handleError
      );
    }

    return next.handle(req).pipe(
      catchError((error) => this.handleError(error)) // Bind `this` to handleError
    );
  }

  private handleError(error: HttpEvent<any>): Observable<never> {
    if(error instanceof HttpErrorResponse && error.status === 401) {
      this.loginService.tokenExpired()
      return of() as Observable<never>;
    }
    else {
      Swal.fire({
        title: error,
        toast: true,
        timer: 2000,
        position: "top-right",
        showConfirmButton: false,
        background: "#f72020",
        color: "#fff"
      });
      return of() as Observable<never>;
    }
  }
}
