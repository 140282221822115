import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PermissionPageComponent } from './permission-page/permission-page.component';
import { UsersRoutingModule } from './user-routing.module';
import { SharedModule } from '../shared/shared.module';
import { FormUserPermissionComponent } from './forms/form-user-permission/form-user-permission.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { FormUpdateUserPermissionComponent } from './forms/form-update-user-permission/form-update-user-permission.component';



@NgModule({
  declarations: [
    PermissionPageComponent,
    FormUserPermissionComponent,
    FormUpdateUserPermissionComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    UsersRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule
  ]
})
export class UsersModule { }
